import { useEffect } from "react"
import NumberInput from "../../components/ui/form/NumberInput"
import TextInput from "../../components/ui/form/TextInput"
import ButtonWithIcon from "../../components/ui/button/ButtonWithIcon"
import {
  BookmarkIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid"
import { useForm, SubmitHandler, Controller } from "react-hook-form"
import BasicValidationMessage from "../ui/validation/BasicValidationMessage"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import classes from "../../assets/styles/ui/Input.module.css"
import clsx from "clsx"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"

export type CourseForm = {
  title: string
  category_ids: number[]
  partnership_id?: number
  price: number
  description: string
  lecturer?: string
  is_generate_redemption_code?: boolean
  amount_generated_redemption_code?: number
}

const CourseManagementForm: React.FC<{
  method: "POST" | "PUT"
  onSubmitHandler: SubmitHandler<CourseForm>
  categoryOptions: {
    label: string
    value: number | string
  }[]
  partnershipOptions: {
    label: string
    value: number | string
  }[]
  serverValidationResponse?: {
    title?: string
    category_ids?: string
    price?: string
    description?: string
  }
  submitting?: boolean
  initialValue?: CourseForm
}> = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    control,
    watch,
  } = useForm<CourseForm>({
    mode: "all",
    defaultValues: props.initialValue,
  })

  useEffect(() => {
    if (props.serverValidationResponse !== undefined) {
      Object.keys(props.serverValidationResponse as object).forEach((field) => {
        const messageVal: string | undefined =
          props.serverValidationResponse![field]

        if (messageVal === undefined) {
          return
        }

        setError(field as keyof CourseForm, {
          type: "custom",
          message: messageVal,
        })
      })
    }
  }, [props.serverValidationResponse])

  return (
    <form
      method={props.method}
      onSubmit={handleSubmit(props.onSubmitHandler)}
    >
      <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
        <div className="sm:col-span-2">
          <TextInput
            inputClass="cms-base-input lg:min-w-[42rem]"
            labelClass="cms-base-label"
            isRequired
            label="Judul Modul Belajar"
            name="title"
            hooks={{
              ...register("title", {
                required: "Judul modul belajar tidak boleh kosong!",
                minLength: {
                  message: "Judul module belajar minimal 3 karakter!",
                  value: 3,
                },
                maxLength: {
                  message:
                    "Judul module belajar tidak boleh melebihi 500 karakter!",
                  value: 500,
                },
              }),
            }}
            validationMessage={
              errors.title && (
                <BasicValidationMessage
                  type="error"
                  message={errors.title.message as string}
                  icon={
                    <ExclamationTriangleIcon className="h-6 w-6 text-red-800" />
                  }
                />
              )
            }
          />
        </div>
        <div className="sm:col-span-2">
          <TextInput
            inputClass="cms-base-input lg:min-w-[42rem]"
            labelClass="cms-base-label"
            label="Mentor"
            name="lecturer"
            hooks={{
              ...register("lecturer"),
            }}
            validationMessage={
              errors.lecturer && (
                <BasicValidationMessage
                  type="error"
                  message={errors.lecturer?.message as string}
                  icon={
                    <ExclamationTriangleIcon className="h-6 w-6 text-red-800" />
                  }
                />
              )
            }
          />
        </div>
        <div className="sm:col-span-2">
          <label className={clsx("cms-base-label", classes.required)}>
            Pilih Kategori Pembelajaran
          </label>
          <Controller
            control={control}
            defaultValue={[]}
            name="category_ids"
            rules={{ required: "Kategori tidak boleh kosong!" }}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <Select
                name={name}
                isMulti
                ref={ref}
                closeMenuOnSelect={false}
                components={makeAnimated()}
                classNames={{
                  container: () =>
                    clsx("lg:w-[42rem]", errors.category_ids && "mb-2"),
                  control: () => "p-2.5 rounded-lg",
                }}
                options={props.categoryOptions}
                value={props.categoryOptions.filter((c) =>
                  value.includes(c.value as number)
                )}
                onChange={(val) =>
                  onChange(val.map((c) => c.value) as number[])
                }
                onBlur={onBlur}
              />
            )}
          />
          {errors.category_ids && (
            <BasicValidationMessage
              type="error"
              message={errors.category_ids?.message as string}
              icon={
                <ExclamationTriangleIcon className="h-6 w-6 text-red-800" />
              }
            />
          )}
        </div>
        <div className="sm:col-span-2">
          <label className={clsx("cms-base-label")}>
            Pilih partnership
          </label>
          <Controller
            control={control}
            name="partnership_id"
            render={({ field: { onChange, value, name, ref } }) => (
              <Select
                ref={ref}
                classNames={{
                  container: () =>
                    clsx("lg:w-[42rem]"),
                  control: () => "p-2.5 rounded-lg",
                }}
                name={name}
                options={props.partnershipOptions}
                value={props.partnershipOptions.find(item => item.value === value)}
                onChange={selectedOption => {
                  const newValue = selectedOption ? selectedOption.value : null;
                  onChange(newValue);
                }}
                isClearable={true}
              />
            )}
          />

        </div>
        <div className="sm:col-span-2">
          <NumberInput
            min={1}
            inputClass="cms-base-input lg:min-w-[42rem]"
            labelClass="cms-base-label"
            isRequired
            label="Harga"
            name="price"
            value={""}
            hooks={{
              ...register("price", {
                required: "Harga tidak boleh kosong!",
                min: {
                  message: "Harga tidak boleh 0!",
                  value: 1,
                },
              }),
            }}
            validationMessage={
              errors.price && (
                <BasicValidationMessage
                  type="error"
                  message={errors.price.message as string}
                  icon={
                    <ExclamationTriangleIcon className="h-6 w-6 text-red-800" />
                  }
                />
              )
            }
          />
        </div>
        <div className="sm:col-span-2">
          <label className={clsx("cms-base-label", classes.required)}>
            Deskripsi Modul Pembelajaran
          </label>
          <div
            className={clsx(
              "cms-base-input lg:max-w-[42rem]",
              errors.description && "mb-2"
            )}
          >
            <Controller
              control={control}
              defaultValue=""
              name="description"
              rules={{
                required: "Deskripsi tidak boleh kosong!",
                minLength: {
                  message: "Deskripsi minimal harus 20 karakter!",
                  value: 20,
                },
              }}
              render={({ field: { onChange, value, ref, onBlur } }) => (
                <ReactQuill
                  ref={ref}
                  onBlur={onBlur}
                  value={value}
                  onChange={(text) => onChange(text)}
                  theme="snow"
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, false] }],
                      ["bold", "italic", "underline", "strike", "blockquote"],
                      [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                      ],
                      ["link"],
                      ["clean"],
                    ],
                  }}
                  formats={[
                    "header",
                    "bold",
                    "italic",
                    "underline",
                    "strike",
                    "blockquote",
                    "list",
                    "bullet",
                    "indent",
                    "link",
                  ]}
                />
              )}
            />
          </div>
          {errors.description && (
            <BasicValidationMessage
              type="error"
              message={errors.description.message as string}
              icon={
                <ExclamationTriangleIcon className="h-6 w-6 text-red-800" />
              }
            />
          )}
        </div>
        {props.method === "POST" &&
          <>
            <div className="sm:col-span-2">
              <TextInput
                type="checkbox"
                className="cms-base-input w-fit bg-slate-100 border-primary checked:text-primary"
                labelClass="cms-base-label w-fit"
                label="Buat Redeem Code?"
                {...register("is_generate_redemption_code")}
              />
            </div>
            <div className="sm:col-span-2">
              <TextInput
                inputClass="cms-base-input lg:min-w-[42rem]"
                labelClass="cms-base-label"
                label="Redeem Code"
                type="number"
                required={watch('is_generate_redemption_code')}
                {
                ...register("amount_generated_redemption_code", {
                  max: {
                    message: 'tidak boleh melebihi 24',
                    value: 24,
                  },
                  min: {
                    message: 'tidak boleh kurang dari 0',
                    value: 0,
                  }
                })
                }
              />
            </div>
          </>
        }
      </div>
      {!props.submitting && (
        <ButtonWithIcon
          action={undefined}
          type="submit"
          class="mt-4 border-2 border-transparent bg-primary text-white space-x-2 hover:bg-transparent hover:text-white hover:border-primary"
        >
          <BookmarkIcon className="h-6 w-6 text-white" />
          <span>{props.method === "POST" ? "Tambahkan" : "Update"}</span>
        </ButtonWithIcon>
      )}
      {props.submitting && (
        <ButtonWithIcon
          action={undefined}
          type="button"
          class="mt-4 border-2 border-transparent bg-primary text-white space-x-2 cursor-not-allowed"
          disabled
        >
          <svg
            aria-hidden="true"
            className="inline w-6 h-6 mr-2 text-gray-400 animate-spin fill-white"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span>Menyimpan...</span>
        </ButtonWithIcon>
      )}
    </form>
  )
}

export default CourseManagementForm
