import { CreateCourseGiftRequest, CreateCourseGiftResponse } from "utils/api/cms/types"
import { requestCMS } from "utils/axiosInstance"

const createCourseGift = async (payload: CreateCourseGiftRequest) => {
  const res = await requestCMS.post<CreateCourseGiftResponse>(
    '/employee/course-gift/create', payload
  )

  return res
}

export default createCourseGift