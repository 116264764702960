import to from "await-to-js"
import Button from "components/ui/button/Button"
import TextInput from "components/ui/form/TextInput"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { CourseRedemptionClaimResponse, GeneralCourseRedemptionClaimResponse } from "utils/api/cms/types"
import { redeemCourse } from "utils/api/user/mutations/functions"
import { useUserInfoStore } from "utils/store/user-info"
import { CMSCourseRedemptionClaimResponse, GeneralAPIValidationResponse } from "utils/types"

type RedeemCourseFormType = {
  course_id: string
  redemption_code: string
}

const ReedemCourseForm: React.FC<{
  course_id: number,
  buyable?: boolean,
}> = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    control,
  } = useForm<RedeemCourseFormType>({
    mode: "all",
  })

  const isLoggedIn = useUserInfoStore((state) => state.isLoggedIn)

  const redeemCourseHandler = async (data) => {
    const [err, response] = await to<
      CMSCourseRedemptionClaimResponse<CourseRedemptionClaimResponse>,
      GeneralAPIValidationResponse
    >(redeemCourse({
      ...data,
      course_id: props.course_id,
    }))

    if (err) {
      toast.error("Error occurred!")
      return
    }

    const isSuccessfullRedeem = response.data.is_successfull

    if (!isSuccessfullRedeem) {
      toast.error(response.data.failure_reason)
      return
    }

    toast.success("Successfully redeem code! Enjoy your course")

    setTimeout(() => {
      window.location.reload();
    }, 1000)
  }

  return (
    <form action="POST" onSubmit={handleSubmit(redeemCourseHandler)}>
      {/* add pattern please such aaaaa-bbbbb */}
      <TextInput
        required
        disabled={!isLoggedIn}
        {
        ...register('redemption_code', {
          required: 'redeem code tidak boleh kosong'
        })
        }
      />
      <TextInput
        name="course_id"
        type="hidden"
        value={`${props.course_id}`}
        hooks={{
          ...register('course_id')
        }}
      />
      <br />
      <Button variant="outlined" disabled={!isLoggedIn || !props.buyable} type="submit">
        Redeem
      </Button>
    </form>
  )
}

export default ReedemCourseForm