import { CourseGiftActivationRequest, CourseGiftActivationResponse } from "utils/api/cms/types";
import { requestUser } from "utils/axiosInstance";

const courseGiftActivation = async (payload: CourseGiftActivationRequest) => {
  const res = await requestUser.post<CourseGiftActivationResponse>(
    "/user/course-gift/activation",
    payload,
  )

  return res
}

export default courseGiftActivation