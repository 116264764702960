import moment from "moment"
import { useLocation, useParams } from "react-router-dom"

export const getCMSSegments: () => string[] = (): string[] => {
  const location = useLocation()
  const params = useParams()

  let paths = location.pathname.split("/")

  paths.forEach((segment, i) => {
    const isParam = Object.keys(params).find((key) => params[key] === segment)
    if (isParam) {
      paths[i - 1] = `${paths[i - 1]}/${segment}`
      return
    }

    return
  })

  return paths.filter((segment) => {
    const isParam = Object.keys(params).find((key) => params[key] === segment)
    return segment !== "cms" && segment !== "" && !isParam
  })
}

export const addCountryCodePhoneNumber = (phoneNumber: string, addPlus: boolean = false, countryCode: string = '62'): string => {
  let phoneNumberStartWithCountryCode = phoneNumber

  if (phoneNumber[0] == '0') {
    phoneNumberStartWithCountryCode = phoneNumber.replace(/^0/, countryCode)
  }

  if (!addPlus) return phoneNumberStartWithCountryCode

  return `+${phoneNumberStartWithCountryCode}`
}

export const getWhatsappLink = (phoneNumber: string, countryCode: string = '62'): string => {
  const phoneNumberWithPlus = addCountryCodePhoneNumber(phoneNumber, true, countryCode)

  return `https://wa.me/${phoneNumberWithPlus}`
}

export const appendTimestamp = (
  name: string,
  timestampFormat: string = 'YYYY_MM_DD_HH_mm_ss'
): string => {
  const now = moment().format(timestampFormat)

  return `${name}_${now}`;
}