import { BookmarkIcon } from "@heroicons/react/24/solid"
import Button from "components/ui/button/Button"
import ButtonWithIcon from "components/ui/button/ButtonWithIcon"
import TextInput from "components/ui/form/TextInput"
import { SubmitHandler, useForm } from "react-hook-form"

export type CourseGiftFormType = {
  recipient_email: string
  remarks: string
}

const CourseGiftForm: React.FC<{
  onSubmit: SubmitHandler<CourseGiftFormType>,
  formType: 'first' | 'second',
  isLoading: boolean,
  buttonClass: string,
  buttonText: string,
}> = ({
  formType = 'first',
  ...props
}) => {
    const {
      register,
      handleSubmit,
      formState: { errors },
      setError,
      control,
    } = useForm<CourseGiftFormType>({
      mode: "all",
    })

    const labelTextInputClass = formType == 'first'
      ? '' : 'cms-base-label mt-4'

    return (
      <form action="POST" onSubmit={handleSubmit(props.onSubmit)}>
        <TextInput
          label="Alamat Email Penerima"
          labelClass={labelTextInputClass}
          required
          type="email"
          errorMessage={errors.recipient_email?.message}
          {
          ...register('recipient_email', {
            required: {
              value: true,
              message: "Email wajib diisi",
            },
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: 'Pastikan email Anda memiliki format "nama@example.com"',
            },
          })
          }
        />
        <TextInput
          label="Catatan"
          labelClass={labelTextInputClass}
          type="textarea"
          placeholder="beri ucapan untuk penerima..."
          {...register('remarks')
          }
        />
        <br />
        {formType == 'first' &&
          <Button
            isLoading={props.isLoading}
            type="submit"
            size="md"
            className={props.buttonClass}
            variant="outlined"
          >
            {props.buttonText}
          </Button>
        }
        {formType == 'second' &&
          <ButtonWithIcon
            action={undefined}
            type="submit"
            class="self-start mt-12 border-2 border-transparent bg-primary text-white space-x-2 hover:bg-transparent hover:text-white hover:border-primary"
            disabled={props.isLoading}
          >
            <BookmarkIcon className="h-6 w-6 text-white" />
            <span>
              {!props.isLoading ? (
                <>{props.buttonText}</>
              ) : (
                "Menyimpan..."
              )}
            </span>
          </ButtonWithIcon>
        }
      </form>
    )
  }

export default CourseGiftForm