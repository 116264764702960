import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom"

import clsx from "clsx"
import { toast } from "react-toastify"
import React, { useEffect, useState } from "react"

import Section from "components/ui/section/Section"
import { useUserInfoStore } from "utils/store/user-info"
import PageProgressbar from "components/ui/loading/PageProgressbar"
import { CourseGiftActivationRequest } from "utils/api/cms/types"
import useMutationCourseGiftActivation from "utils/api/cms/mutations/hooks/course/useMutationCourseGiftActivation"

const CourseGiftActivation = () => {
  const { gift_code_uuid } = useParams()
  const navigate = useNavigate()

  const isLoggedIn = useUserInfoStore((state) => state.isLoggedIn)

  const activationRequest: CourseGiftActivationRequest = {
    gift_code_uuid: gift_code_uuid ?? ''
  }

  const [loadComponent, setLoadComponent] = useState(false)

  const courseGiftActivation = useMutationCourseGiftActivation({
    onSuccess(data) {
      if (!data.success) {
        toast.error("Aktivasi tidak berhasil")
        return
      }

      toast.success("Aktivasi gift course berhasil")
      navigate("/courses")
    },
    onError(error) {
      toast.error("Aktivasi gift course gagal")
      navigate("/")
    },
  })

  // bug: mutation called multiple
  useEffect(() => {
    if (courseGiftActivation.isIdle) {
      courseGiftActivation.mutateAsync(activationRequest)
    }
  }, [courseGiftActivation.mutate])

  if (!isLoggedIn) {
    return <Navigate to="/register" />
  }
  if (!loadComponent) return <PageProgressbar finished={false} />

  return (
    <Section space="top-bottom">
      <div
        className={clsx(
          "flex flex-col items-center justify-center",
          "mx-8 md:mx-auto",
          "max-w-5xl",
          "rounded",
          "space-y-4",
          "md:py-16 md:px-[200px] md:shadow"
        )}
      ></div>
    </Section>
  )
}

export default CourseGiftActivation
