import { useEffect, useState } from "react"
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form"
import TextInput from "../ui/form/TextInput"
import NumberInput from "../ui/form/NumberInput"
import BasicValidationMessage from "../ui/validation/BasicValidationMessage"
import {
  BookmarkIcon,
  ExclamationTriangleIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/solid"
import makeAnimated from "react-select/animated"
import clsx from "clsx"
import classes from "../../assets/styles/ui/Input.module.css"
import Select from "react-select"
import ButtonWithIcon from "../ui/button/ButtonWithIcon"
import ReactQuill from "react-quill"
import moment, { Moment } from "moment"
import BorderedRadioInput from "../ui/form/BorderedRadioInput"
import ReactDatePicker from "react-datepicker"

import "react-datepicker/dist/react-datepicker.css"

export type CreateWebinarRequest = {
  title: string
  description: string
  price: number
  quota: number
  extras: string
  start_date: string
  end_date: string
  category_ids: number[]
}

export type WebinarForm = {
  title: string
  description: string
  price: number
  quota: number
  action_date: {
    start_date: Date | null
    end_date: Date | null
  }
  category_ids: number[]
  location: string
  speakers: { value: string }[]
  execution_type: {
    online: boolean
    offline: boolean
  }
}

const WebinarManagementForm: React.FC<{
  method: "POST" | "PUT"
  onSubmitHandler: SubmitHandler<WebinarForm>
  categoryOptions: {
    label: string
    value: number | string
  }[]
  serverValidationResponse?: {
    title: string | undefined
    description: string | undefined
    price: string | undefined
    quota: string | undefined
    extras: string | undefined
    start_date: string | undefined
    end_date: string | undefined
    category_ids: string | undefined
  }
  submitting?: boolean
  initialValue?: WebinarForm
}> = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    control,
    getValues,
    setValue,
  } = useForm<WebinarForm>({
    mode: "all",
    defaultValues: {
      ...props.initialValue,
      speakers: props.initialValue?.speakers
        ? props.initialValue.speakers
        : [{ value: "" }],
    },
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: "speakers",
    rules: {
      minLength: 1,
    },
  })
  const payloadDateFormat = "YYYY-MM-DD HH:mm"
  const [showLocation, setShowLocation] = useState(false)

  useEffect(() => {
    if (props.serverValidationResponse !== undefined) {
      Object.keys(props.serverValidationResponse as object).forEach((field) => {
        const messageVal: string | undefined =
          props.serverValidationResponse![field]

        if (messageVal === undefined) {
          return
        }

        setError(field as keyof WebinarForm, {
          type: "custom",
          message: messageVal as string,
        })
      })
    }
  }, [props.serverValidationResponse])

  return (
    <form
      className="pb-4"
      method={props.method}
      onSubmit={handleSubmit(props.onSubmitHandler)}
    >
      <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
        <div className="sm:col-span-2">
          <TextInput
            inputClass="cms-base-input min-w-[42rem]"
            labelClass="cms-base-label"
            isRequired
            label="Judul Modul Webinar"
            name="title"
            value=""
            hooks={{
              ...register("title", {
                required: "Judul webinar tidak boleh kosong!",
                minLength: {
                  message:
                    "Judul webinar harus memiliki setidaknya 3 karakter!",
                  value: 3,
                },
                maxLength: {
                  message: "Judul webinar tidak boleh melebihi 500 karakter!",
                  value: 500,
                },
              }),
            }}
            validationMessage={
              errors.title && (
                <BasicValidationMessage
                  type="error"
                  message={errors.title?.message as string}
                  icon={
                    <ExclamationTriangleIcon className="h-6 w-6 text-red-800" />
                  }
                />
              )
            }
          />
        </div>
        <div className="sm:col-span-2">
          <label className={clsx("cms-base-label", classes.required)}>
            Pilih Kategori Webinar
          </label>
          <Controller
            control={control}
            defaultValue={[]}
            name="category_ids"
            rules={{ required: "Kategori tidak boleh kosong!" }}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <Select
                name={name}
                isMulti
                ref={ref}
                closeMenuOnSelect={false}
                components={makeAnimated()}
                classNames={{
                  container: () =>
                    clsx("w-[42rem]", errors.category_ids && "mb-2"),
                  control: () => "p-2.5 rounded-lg",
                }}
                options={props.categoryOptions}
                value={props.categoryOptions.filter((c) =>
                  value.includes(c.value as number)
                )}
                onChange={(val) =>
                  onChange(val.map((c) => c.value) as number[])
                }
                onBlur={onBlur}
              />
            )}
          />
          {errors.category_ids && (
            <BasicValidationMessage
              type="error"
              message={errors.category_ids?.message as string}
              icon={
                <ExclamationTriangleIcon className="h-6 w-6 text-red-800" />
              }
            />
          )}
        </div>
        <div className="sm:col-span-2">
          <NumberInput
            min={0}
            inputClass="cms-base-input min-w-[42rem]"
            labelClass="cms-base-label"
            isRequired
            label="Harga Webinar"
            name="price"
            value=""
            hooks={{
              ...register("price", {
                required: "Harga webinar tidak boleh kosong!",
                min: {
                  message: "Harga webinar harus bernilai >= 0!",
                  value: 0,
                },
              }),
            }}
            validationMessage={
              errors.price && (
                <BasicValidationMessage
                  type="error"
                  message={errors.price?.message as string}
                  icon={
                    <ExclamationTriangleIcon className="h-6 w-6 text-red-800" />
                  }
                />
              )
            }
          />
        </div>
        <div className="sm:col-span-2">
          <NumberInput
            min={1}
            inputClass="cms-base-input min-w-[42rem]"
            labelClass="cms-base-label"
            isRequired={false}
            label="Kuota Webinar"
            name="quota"
            value=""
            hooks={{
              ...register("quota", {
                min: {
                  message: "Quota webinar harus bernilai >= 0!",
                  value: 1,
                },
              }),
            }}
            validationMessage={
              errors.quota && (
                <BasicValidationMessage
                  type="error"
                  message={errors.quota?.message as string}
                  icon={
                    <ExclamationTriangleIcon className="h-6 w-6 text-red-800" />
                  }
                />
              )
            }
          />
        </div>
        <div className="sm:col-span-2">
          <label className={clsx("cms-base-label", classes.required)}>
            Deskripsi Modul Webinar
          </label>
          <div
            className={clsx(
              "cms-base-input max-w-[42rem]",
              errors.description && "mb-2"
            )}
          >
            <Controller
              control={control}
              defaultValue=""
              name="description"
              rules={{
                required: "Deskripsi tidak boleh kosong!",
                minLength: {
                  message: "Deskripsi minimal harus 20 karakter!",
                  value: 20,
                },
              }}
              render={({ field: { onChange, value, ref, onBlur } }) => (
                <ReactQuill
                  ref={ref}
                  onBlur={onBlur}
                  value={value}
                  onChange={(text) => onChange(text)}
                  theme="snow"
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, false] }],
                      ["bold", "italic", "underline", "strike", "blockquote"],
                      [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                      ],
                      ["link"],
                      ["clean"],
                    ],
                  }}
                  formats={[
                    "header",
                    "bold",
                    "italic",
                    "underline",
                    "strike",
                    "blockquote",
                    "list",
                    "bullet",
                    "indent",
                    "link",
                  ]}
                />
              )}
            />
          </div>
          {errors.description && (
            <BasicValidationMessage
              type="error"
              message={errors.description.message as string}
              icon={
                <ExclamationTriangleIcon className="h-6 w-6 text-red-800" />
              }
            />
          )}
        </div>
        <div className="sm:col-span-2 max-w-[42rem]">
          <label className={clsx("cms-base-label", classes.required)}>
            Tanggal Pelaksanaan Webinar
          </label>
          <div>
            <Controller
              control={control}
              name="action_date"
              rules={{
                validate: (action_date) => {
                  const endSameOrSmaller = moment(
                    action_date.end_date,
                    payloadDateFormat
                  ).isSameOrBefore(
                    moment(action_date.start_date, payloadDateFormat)
                  )

                  if (endSameOrSmaller)
                    return "Tanggal selesai pelaksanaan tidak boleh sebelum atau sama dengan tanggal pelaksanaan dimulai!"
                  if (
                    action_date === undefined ||
                    !action_date.start_date ||
                    !action_date.end_date
                  )
                    return "Tanggal pelaksanaan tidak boleh kosong!"

                  return
                },
              }}
              render={({ field: { onChange } }) => (
                <div className="flex items-center w-full gap-2">
                  <div className="w-full">
                    <ReactDatePicker
                      className="w-full rounded-lg"
                      wrapperClassName="w-full"
                      selected={getValues("action_date.start_date")}
                      selectsStart
                      startDate={getValues("action_date.start_date")}
                      endDate={getValues("action_date.end_date")}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      onChange={(update) => {
                        onChange({
                          start_date: update,
                        })
                      }}
                      withPortal
                      showTimeSelect
                    />
                  </div>
                  <span className="text-white">-</span>
                  <div className="w-full">
                    <ReactDatePicker
                      className="w-full rounded-lg"
                      selected={getValues("action_date.end_date")}
                      selectsEnd
                      startDate={getValues("action_date.start_date")}
                      endDate={getValues("action_date.end_date")}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      onChange={(update) => {
                        onChange({
                          start_date: getValues("action_date.start_date"),
                          end_date: update,
                        })
                      }}
                      withPortal
                      showTimeSelect
                    />
                  </div>
                </div>
              )}
            />
          </div>
          {errors.action_date && (
            <BasicValidationMessage
              type="error"
              message={errors.action_date.message as string}
              icon={
                <ExclamationTriangleIcon className="h-6 w-6 text-red-800" />
              }
            />
          )}
        </div>
        <div className="sm:col-span-2 max-w-[42rem]">
          <Controller
            control={control}
            name="execution_type"
            rules={{
              validate: (executionType) => {
                if (
                  executionType === undefined ||
                  (!executionType.offline && !executionType.online)
                )
                  return "Pilih salah satu untuk jenis pelaksanaan"

                return
              },
            }}
            render={({ field }) => (
              <BorderedRadioInput
                isRequired
                optionsWrapper="grid md:grid-cols-2 gap-6"
                value={
                  !field.value ? "" : field.value.online ? "ONLINE" : "OFFLINE"
                }
                inputClass=""
                label="Jenis Pelaksanaan"
                name="webinar_execution"
                labelClass="cms-base-label"
                options={[
                  {
                    label: "Online",
                    value: "ONLINE",
                  },
                  {
                    label: "Offline",
                    value: "OFFLINE",
                  },
                ]}
                changeHandler={(event) => {
                  const online = event.target.value === "ONLINE"
                  const offline = event.target.value === "OFFLINE"
                  setShowLocation(offline)
                  field.onChange({
                    ...field.value,
                    online: online,
                    offline: offline,
                  })
                }}
                validationMessage={
                  errors.execution_type && (
                    <BasicValidationMessage
                      type="error"
                      message={errors.execution_type.message as string}
                      icon={
                        <ExclamationTriangleIcon className="h-6 w-6 text-red-800" />
                      }
                    />
                  )
                }
              />
            )}
          />
        </div>
        {showLocation && (
          <div className="sm:cols-span-2">
            <TextInput
              inputClass="cms-base-input min-w-[42rem]"
              labelClass="cms-base-label"
              isRequired
              label="Lokasi Pelaksanaan Webinar"
              name="location"
              value=""
              hooks={{
                ...register("location", {
                  required: "Lokasi webinar tidak boleh kosong",
                }),
              }}
              validationMessage={
                errors.location && (
                  <BasicValidationMessage
                    type="error"
                    message={errors.location?.message as string}
                    icon={
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-800" />
                    }
                  />
                )
              }
            />
          </div>
        )}
        <div className="sm:col-span-2 w-[42rem]">
          <div className="grid grid-flow-row auto-rows-max gap-y-2">
            {fields.map((_, index) => (
              <div key={index}>
                <TextInput
                  label={index === 0 ? "Nama Pembicara" : ""}
                  labelClass={index === 0 ? "cms-base-label" : ""}
                  inputClass={clsx(
                    "cms-base-input min-w-[42rem]",
                    errors.speakers &&
                    errors.speakers![index]?.value?.message &&
                    "mb-2"
                  )}
                  name={`speakers_${index}`}
                  isRequired
                  value=""
                  hooks={{
                    ...register(`speakers.${index}.value`, {
                      required: `Nama speaker ini tidak boleh kosong!`,
                    }),
                  }}
                  validationMessage={
                    errors.speakers &&
                      errors.speakers![index]?.value?.message ? (
                      <BasicValidationMessage
                        type="error"
                        message={
                          errors.speakers[index]?.value?.message as string
                        }
                        icon={
                          <ExclamationTriangleIcon className="h-6 w-6 text-red-800" />
                        }
                      />
                    ) : undefined
                  }
                />
                {index > 0 && (
                  <ButtonWithIcon
                    action={(e) => {
                      e.preventDefault()
                      remove(index)
                    }}
                    sizeClass="p-1"
                    type="submit"
                    class="mt-1 border-2 border-transparent bg-red-600 text-white space-x-2 hover:bg-transparent hover:text-white hover:border-red-600"
                  >
                    <TrashIcon className="h-5 w-5 text-white" />
                  </ButtonWithIcon>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="sm:col-span-2">
          <ButtonWithIcon
            action={(e) => {
              e.preventDefault()
              append({ value: "" })
            }}
            type="submit"
            class="mt-1 border-2 border-transparent bg-blue-600 text-white space-x-2 hover:bg-transparent hover:text-white hover:border-blue-600"
          >
            <PlusIcon className="h-6 w-6 text-white" />
            <span>Tambah pembicara</span>
          </ButtonWithIcon>
        </div>
      </div>
      {!props.submitting && (
        <ButtonWithIcon
          action={undefined}
          type="submit"
          class="mt-4 border-2 border-transparent bg-primary text-white space-x-2 hover:bg-transparent hover:text-white hover:border-primary"
        >
          <BookmarkIcon className="h-6 w-6 text-white" />
          <span>{props.method === "POST" ? "Tambahkan" : "Update"}</span>
        </ButtonWithIcon>
      )}
      {props.submitting && (
        <ButtonWithIcon
          action={undefined}
          type="button"
          class="mt-4 border-2 border-transparent bg-primary text-white space-x-2 cursor-not-allowed"
          disabled
        >
          <svg
            aria-hidden="true"
            className="inline w-6 h-6 mr-2 text-gray-400 animate-spin fill-white"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span>Menyimpan...</span>
        </ButtonWithIcon>
      )}
    </form>
  )
}

export default WebinarManagementForm
