import CourseGift from "components/cms/Course/gift/CourseGift"
import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "components/ui/breadcrumb/DefBreadcrumb"
import Typography from "components/ui/typography"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import useMutationCreateCourseGift from "utils/api/cms/mutations/hooks/course/useMutationCreateCourseGift"
import { getCMSSegments } from "utils/helper"

const CourseGiftCreate = () => {
  const segments = getCMSSegments()
  const params = useParams()
  const navigate = useNavigate()

  const { mutate, isLoading } = useMutationCreateCourseGift({
    onSuccess(data) {
      toast.success("Succes Create new Gift")
      navigate(`/cms/course/gifts/${data.data.course_id}`)
    },
    onError(err) {
      toast.error(err.message)
    },
  })

  const handleCreate = (data) => {
    const requestData = {
      ...data,
      course_id: params.id
    }

    mutate(requestData)
  }

  return (
    <EmployeeDashboardWrapper>
      <DefBreadcrumb
        segments={segments}
        prefix="cms"
      />
      <div className="inline-flex items-center">
        <Typography
          variant="subheading4"
          as="h1"
          className="text-primary mt-4"
        >
          Form Tambah Redeem Code
        </Typography>
      </div>

      <CourseGift
        formType="second"
        onSubmit={handleCreate}
        isLoading={isLoading}
        buttonClass={''}
      />

    </EmployeeDashboardWrapper>
  )
}

export default CourseGiftCreate
