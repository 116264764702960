import { useMutation } from "@tanstack/react-query"
import { UpdateWebinarRequest } from "../../types"
import { CMSCreateWebinarResponse, GeneralMutationParams, Webinar } from "utils/types"
import updateWebinar from "../functions/updateWebinar"

const useMutationUpdateWebinar = (
  options?: GeneralMutationParams<
    CMSCreateWebinarResponse<Webinar>,
    UpdateWebinarRequest
  >
) => {
  return useMutation({
    mutationFn: ({ id, payload }) =>
      updateWebinar(id, payload),
    ...options,
  })
}

export default useMutationUpdateWebinar