import { GeneralGenerateCourseRedemptionCodeResponse, GenerateCourseRedemptionCodePayload } from "utils/api/cms/types"
import { requestCMS } from "utils/axiosInstance"

const generateCourseRedemptionCode = async (course_id: number, payload: GenerateCourseRedemptionCodePayload) => {
    const res = await requestCMS.post<GeneralGenerateCourseRedemptionCodeResponse>(
        `/employee/course/${course_id}/add-redeem-codes`,
        payload
    )
    return res
}

export default generateCourseRedemptionCode
