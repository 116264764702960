import { CourseRedemptionClaimRequest, CourseRedemptionClaimResponse } from "utils/api/cms/types"
import { requestUser } from "utils/axiosInstance"
import { CMSCourseRedemptionClaimResponse } from "utils/types"

const redeemCourse = async (payload: CourseRedemptionClaimRequest) => {
  const res = await requestUser.post<CMSCourseRedemptionClaimResponse<CourseRedemptionClaimResponse>>(
    '/user/course/redeem',
    payload
  )

  return res
}

export default redeemCourse