import { useNavigate, useParams, Link } from "react-router-dom"
import { toast } from "react-toastify"
import {
  useGetCourseDetail,
  useGetWorkshopDetail,
} from "utils/api/public/queries/hooks"
import { Helmet } from "react-helmet"
import Typography from "components/ui/typography"
import { ShoppingCartIcon } from "@heroicons/react/24/solid"
import { useState } from "react"
import TextInput from "components/ui/form/TextInput"
import useMutationReferral from "utils/api/employee/mutations/hooks/useMutationReferral"
import { ReferralResponse } from "utils/types"
import Button from "components/ui/button/Button"
import { useUserInfoStore } from "utils/store/user-info"
import { useMutationBuyProduct } from "utils/api/payment/mutations/hooks"
import { BuyProductRequest } from "utils/api/payment/types"
import { useMediaQuery } from "usehooks-ts"
import { Badge } from "flowbite-react"
import Swal from "sweetalert2"
import CourseGift from "components/cms/Course/gift/CourseGift"

const CheckoutIndex = () => {
  const params = useParams<{ type: string; slug: string }>()
  const desktop = useMediaQuery("(min-width: 1024px)")
  const mobile = !desktop
  const navigate = useNavigate()
  const formatter = new Intl.NumberFormat("id-ID")
  const isLoggedIn = useUserInfoStore((state) => state.isLoggedIn)
  const [isAddReferral, setIsAddReferral] = useState(false)
  const [isCouponApplied, setIsCouponApplied] = useState(false)
  const [couponData, setCouponData] = useState<ReferralResponse | null>(null)
  const [referral, setReferral] = useState("")
  const [isGiveaway, setIsGiveaway] = useState<boolean>(false);
  const buttonClass = params.type === "webinar" ? "w-full mt-20" : "w-full"

  const { mutate: mutateCoupon } = useMutationReferral({
    onSuccess(data) {
      toast.success("Referral berhasil ditambahkan!")
      setCouponData(data)
      setIsCouponApplied(true)
    },
    onError(err) {
      toast.error(err.message)
    },
  })

  const { data: dataDetailCourse } = useGetCourseDetail({
    options: {
      enabled: params.type === "course",
    },
    slug: params.slug ?? "",
  })

  const { data: dataDetailWebinar } = useGetWorkshopDetail({
    options: {
      enabled: params.type === "webinar",
    },
    slug: params.slug ?? "",
  })

  const productName = params.type === "course" ? "Kelas" : "Webinar"
  const dataProduct = dataDetailCourse ?? dataDetailWebinar

  const sendReferral = () => {
    const courseId = dataProduct?.data.id.toString() || ""
    const encodedCourseId = btoa(courseId)

    const payload = {
      course_id: encodedCourseId,
      referral_code: referral,
    }
    mutateCoupon(payload)
  }

  const isDiscount =
    dataProduct?.data.price.current_price !==
    dataProduct?.data.price.master_price
  const calculateTotalWithReferral = () => {
    const originalPrice = Number(dataProduct?.data.price.current_price) || 0
    const referralDiscount = couponData ? Number(couponData.data.value) : 0
    const totalWithReferral = originalPrice - referralDiscount

    return Math.max(totalWithReferral, 0)
  }

  const totalPayment = calculateTotalWithReferral()
  const formattedTotalPayment = formatter.format(totalPayment)
  const { mutate: mutateBuyProduct, isLoading: isLoadingBuyProduct } =
    useMutationBuyProduct({
      onSuccess(data) {
        if (!dataProduct) return
        if (dataProduct.data.price.current_price_text === '0') {
          Swal.fire('Transaksi berhasil', 'Anda telah berhasil mendaftar untuk workshop gratis!', 'success')
          navigate('/my-workshop')
        } else {
          navigate(
            `/payment/${params.type}/${dataProduct.data.slug}/show/${data.data.payment_token}`
          )
        }
      },
    })


  const handleCheckout = (gift) => {
    if (!isLoggedIn) {
      navigate('/register')
      return
    }

    if (!dataProduct?.data?.id) return
    const encodedId = btoa(String(dataProduct?.data.id))

    let productData: BuyProductRequest = {
      buyable: encodedId,
      type: params.type === "courses" ? "COURSE" : "WEBINAR",
      referral_code: isCouponApplied ? referral : undefined,
      is_giveaway: isGiveaway,
    }

    if (gift !== null) {
      productData = {
        ...productData,
        gift,
      }
    }

    mutateBuyProduct(productData)
  }
  return (
    <>
      <Helmet>
        <title>Checkout: {dataProduct?.data.title ?? ""}</title>
      </Helmet>
      <div className={`container ${desktop ? "my-20" : "my-10"}`}>
        <Typography
          as="h1"
          variant="heading4"
          className="flex text-primary space-x-2 items-center justify-center"
        >
          <ShoppingCartIcon
            width={34}
            height={34}
          />
          <span>Checkout</span>
        </Typography>
        <Typography
          as="h2"
          variant="heading6"
          className="flex text-primary space-x-2 items-center justify-center mt-5"
        >
          <span>{dataProduct?.data.title}</span>
        </Typography>
      </div>
      <div className="container mx-auto px-4 mb-20">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <img
              src={dataProduct?.data.thumbnail}
              alt={dataProduct?.data.title}
            />
          </div>
          <div>
            <Typography
              as="h2"
              variant="heading6"
              className="mb-6"
            >
              Detail Pembayaran
            </Typography>
            <ul className="space-y-1.5 w-full">
              <li className={`flex justify-between ${mobile ? "my-5" : ""}`}>
                <Typography>{dataProduct?.data.title}</Typography>
              </li>
              <li className="flex justify-between">
                <Typography>Harga</Typography>
                {dataProduct?.data.price.current_price_text === '0' ? (
                  <Badge className="px-2 w-12 text-center" color="success">Free</Badge>
                ) : (
                  <>
                    <Typography
                      variant="subheading4"
                      className="font-semibold"
                    >
                      {dataProduct?.data.price.currency_text}
                      {dataProduct?.data.price.current_price_text}
                    </Typography>
                    {isDiscount && (
                      <Typography className="font-normal text-gray-400 line-through decoration-2">
                        {dataProduct?.data.price.currency_text}
                        {dataProduct?.data.price.master_price_text}
                      </Typography>
                    )}
                  </>
                )}

              </li>
              {isCouponApplied && (
                <li className="flex justify-between">
                  <Typography>Referral</Typography>
                  <Typography>
                    Rp.-
                    {couponData &&
                      formatter.format(Number(couponData?.data.value))}
                  </Typography>
                </li>
              )}
              {isDiscount && (
                <li className="flex justify-between">
                  <Typography>Potongan</Typography>
                  <Typography>
                    -{dataProduct?.data.price.currency_text}
                    {formatter.format(
                      (dataProduct?.data.price.master_price ?? 0) -
                      (dataProduct?.data.price.current_price ?? 0)
                    )}
                  </Typography>
                </li>
              )}
              <li className="flex justify-between">
                <Typography className="font-semibold">
                  Total Pembayaran
                </Typography>
                {formattedTotalPayment != '0' ? (
                  <Typography className="font-semibold">
                    {dataProduct?.data.price.currency_text}
                    {formattedTotalPayment}
                  </Typography>
                ) : (
                  <Typography className="px-2 w-12 text-center">-</Typography>
                )}
              </li>
            </ul>
            {params.type === "courses" && (
              <Typography
                as="h6"
                variant="heading6"
                className="mb-6 mt-5"
                onClick={() => setIsAddReferral((v) => !v)}
              >
                Punya Kode Referral?
              </Typography>
            )}
            {params.type === "courses" && (
              <div className="flex gap-2 mb-6">
                <div className="flex-auto">
                  <TextInput
                    name="referral"
                    value={referral}
                    onChange={(e) => setReferral(e.target.value)}
                  ></TextInput>
                </div>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={sendReferral}
                >
                  Tambahkan
                </Button>
              </div>
            )}
            {!isGiveaway &&
              <>
                <Button
                  size="md"
                  className={buttonClass}
                  variant="outlined"
                  as={isLoggedIn ? "button" : Link}
                  isLoading={isLoadingBuyProduct}
                  disabled={isLoggedIn && !dataProduct?.data.buyable}
                  {...(isLoggedIn
                    ? {
                      onClick: () => handleCheckout(null),
                    }
                    : {
                      to: "/register",
                    })}
                >
                  {isLoggedIn ?
                    (dataProduct?.data.buyable ? "Pembayaran" : "Terbeli") :
                    "Daftar & Beli"}
                </Button>
                <div className="flex justify-center gap-1">
                  <Typography
                    variant="text3"
                    className="text-gray-500 tracking-wider"
                  >
                    Ingin Menghadiahkan course?
                  </Typography>
                  <Link
                    to=""
                    onClick={() => {
                      setIsGiveaway(true)
                    }}
                    className="text-sm font-semibold tracking-wider text-secondary hover:underline"
                  >
                    hadiahkan course
                  </Link>
                </div>
              </>
            }
            {isGiveaway &&
              <>
                <CourseGift
                  onSubmit={handleCheckout}
                  isLoading={isLoadingBuyProduct}
                  buttonClass={buttonClass}
                  buttonText={isLoggedIn ?
                    "Belikan untuk seseorang" :
                    "Daftar & Beli"
                  }
                />
                <div className="flex justify-center gap-1">
                  <Typography
                    variant="text3"
                    className="text-gray-500 tracking-wider"
                  >
                    Ingin Membeli course?
                  </Typography>
                  <Link
                    to=""
                    onClick={() => {
                      setIsGiveaway(false)
                    }}
                    className="text-sm font-semibold tracking-wider text-secondary hover:underline"
                  >
                    beli course
                  </Link>
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default CheckoutIndex
