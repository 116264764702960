import { requestCMS } from "utils/axiosInstance"
import type { CreateWebinarRequest, UploadThumbnail, UploadThumbnailResponse } from "../../types"
import { CMSCreateWebinarResponse, Webinar } from "utils/types"

const updateWebinar = async (
  id: number,
  payload: CreateWebinarRequest
) => {
  const res = await requestCMS.put<CMSCreateWebinarResponse<Webinar>>(
    `/employee/webinar/update/${id}`,
    payload,
  )

  return res
}

export default updateWebinar