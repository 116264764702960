import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Helmet } from "react-helmet"
import { useScript } from "hooks/useScript"
import Typography from "components/ui/typography"

import {
  useGetCourseDetail,
  useGetWorkshopDetail,
} from "utils/api/public/queries/hooks"
import { callbackPaymentMidtransResponse } from "utils/api/payment/mutations/functions"
import to from "await-to-js"
import { CallbackPaymentMidtrans } from "utils/api/payment/types"

declare global {
  interface Window {
    snap: {
      embed: (
        token: string,
        embedObj: {
          embedId: string
          onSuccess?: (result: CallbackPaymentMidtrans) => void
        }
      ) => void
      hide: () => void
      init: () => void
      pay: (token: string) => void
      reset: () => void
      show: () => void
    }
  }
}

const PaymentIndex = () => {
  const navigate = useNavigate()
  const params = useParams<{ slug: string; token: string; type: string }>()
  const status = useScript(process.env.REACT_APP_MIDTRANS_SNAP_URL ?? "", {
    removeOnUnmount: true,
    attributes: [
      {
        attribute: "data-client-key",
        value: process.env.REACT_APP_MIDTRANS_CLIENT_KEY ?? "",
      },
    ],
  })

  const [alreadySendCallback, setAlreadySendCallback] = useState<boolean>(false);

  useEffect(() => {
    if (status !== "ready") return
    if (!params.token || !window.snap) return

    window.snap.embed(params.token, {
      embedId: "checkout-container",
      onSuccess: async (result) => {
        if (alreadySendCallback)
          return

        // multiple send, avoid them
        setAlreadySendCallback(true)
        await to(callbackPaymentMidtransResponse(result))
      },
    })
  }, [status, params.token, navigate])

  const { data: dataDetailCourse } = useGetCourseDetail({
    options: {
      enabled: params.type === "course",
    },
    slug: params.slug ?? "",
  })

  const { data: dataDetailWebinar } = useGetWorkshopDetail({
    options: {
      enabled: params.type === "webinar",
    },
    slug: params.slug ?? "",
  })

  const productName = params.type === "course" ? "Kelas" : "Webinar"
  const dataProduct = dataDetailCourse ?? dataDetailWebinar

  return (
    <>
      <Helmet>
        <title>Pembayaran {productName} </title>
      </Helmet>
      <div className="container mt-5">
        <Typography
          as="h1"
          variant="heading4"
          className="flex text-primary space-x-2 items-center justify-center"
        >Pembayaran
        </Typography>
        <Typography
          as="h2"
          variant="heading6"
          className="flex text-primary space-x-2 items-center justify-center mt-3"
        >
          <span>{dataProduct?.data.title}</span>
        </Typography>
        <div className="mt-5 flex flex-col-reverse gap-12 md:flex-row">
          <div
            id="checkout-container"
            className="w-full"
          />
        </div>
      </div>
    </>
  )
}

export default PaymentIndex
