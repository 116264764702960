import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { PaginationState, createColumnHelper } from "@tanstack/react-table"
import ReactTable from "components/ui/react-table/ReactTable"
import type { CareerUsers } from "utils/api/cms/types"
import CMSDefaultTableListFilter from "./CMSDefaultTableListFilter"
import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import useGetCareerUsers from "utils/api/cms/queries/hooks/useGetCareerUsers"
import ExcelExportButton from "components/ui/button/ExcelExportButton"
import api from "utils/axiosInstance"

const CareerUsersList = () => {
  const params = useParams()

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 1,
  })
  const [lastPage, setLastPage] = useState(1)
  const [searchFilter, setSearchFilter] = useState("")
  const [perPageLimit, setPerPageLimit] = useState(10)
  const [dataCareerUsers, setDataCareerUsers] = useState<CareerUsers[]>([])

  const { data: resultResponse } = useGetCareerUsers({
    options: {
      staleTime: 0,
    },
    qParams: {
      limit: perPageLimit,
      page: pagination.pageIndex + 1,
      keyword: searchFilter,
      career_id: Number(params.id),
    },
  })

  useEffect(() => {
    setDataCareerUsers(resultResponse?.data || [])
    setLastPage(resultResponse?.meta?.last_page || 1)
  }, [resultResponse])

  const careerColumnHelper = createColumnHelper<CareerUsers>()

  const careerColumns = [
    careerColumnHelper.accessor("name", {
      header: "Nama Peserta",
      cell: (props) => props.getValue(),
    }),
    careerColumnHelper.accessor("email", {
      header: "Email",
      cell: (props) => props.getValue(),
    }),
    careerColumnHelper.accessor('phone_number', {
      header: "Nomor Telepon",
      cell: (props) => props.getValue(),
    }),
    careerColumnHelper.accessor("address", {
      header: "Alamat",
      cell: (props) => props.getValue(),
    }),
    careerColumnHelper.accessor('linkedin_profile_link', {
      header: "Link Profile Linkedin",
      cell: (props) => (
        props.getValue() ?
          <a href={props.getValue()}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600"
          >
            Lihat Profile Linkedin
          </a>
          : ""
      )
    }),
    careerColumnHelper.accessor("cv", {
      header: "CV",
      cell: (props) => (
        <a
          href={props.getValue()}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600"
        >
          Lihat Dokumen
        </a>
      ),
    }),
    careerColumnHelper.accessor("portfolio", {
      header: "Portfolio",
      cell: (props) => {
        const cvUrl = props.getValue()
        return cvUrl ? (
          <a
            href={cvUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600"
          >
            Lihat Dokumen
          </a>
        ) : (
          "-"
        )
      },
    }),
  ]

  const handleSearch = (keyword: string) => {
    setSearchFilter(keyword)
    setPagination((prevPagination) => ({ ...prevPagination, pageIndex: 0 }))
  }

  return (
    <EmployeeDashboardWrapper>
      <CMSDefaultTableListFilter
        pageSelector
        searchPlaceHolder="Cari"
        wrapperClass="ml-auto flex flex-row space-x-6"
        onSearchHandler={handleSearch}
        perPageHandler={(selected) => setPerPageLimit(selected)}
      />
      <ExcelExportButton
        filename="career_user_export_excel"
        excelExportRequest={() => api.cms.getExportCareerUserExcel(Number(params.id))}
      />
      <ReactTable
        columns={careerColumns}
        data={dataCareerUsers ?? []}
        pagination={pagination}
        onPaginationChange={setPagination}
        pageCount={lastPage}
      />
    </EmployeeDashboardWrapper>
  )
}

export default CareerUsersList
