
import { useMutation } from "@tanstack/react-query"
import { GeneralGenerateCourseRedemptionCodeResponse, GenerateCourseRedemptionCodeRequest } from "utils/api/cms/types"
import generateCourseRedemptionCode from "../../functions/course/generateCourseRedemptionCode"
import { GeneralMutationParams } from "utils/types"

const useMutationGenerateCourseRedemptionCode = (
  options?: GeneralMutationParams<
    GeneralGenerateCourseRedemptionCodeResponse,
    GenerateCourseRedemptionCodeRequest
  >
) => {
  return useMutation({
    mutationFn: ({ id, payload }) => generateCourseRedemptionCode(id, payload),
    ...options,
  })
}

export default useMutationGenerateCourseRedemptionCode
