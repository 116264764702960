import { Suspense, lazy } from "react"
import { RouteObject } from "react-router-dom"

import GeneralPublicLayout from "components/layouts/GeneralPublicLayout"
import UserDashboardLayout from "components/layouts/UserDashboardLayout"
import HomePage from "pages/public/HomePage"
import Login from "pages/public/Login"
import Register from "pages/public/Register"
import Verification from "pages/public/email-verification/EmailVerification"
import VerificationFailed from "pages/public/email-verification/VerificationFailed"
import VerificationSuccess from "pages/public/email-verification/VerificationSuccess"
import ResendEmail from "pages/public/email-verification/ResendEmail"
import ForgotPassword from "pages/public/forgot-password/ForgotPassword"
import Courses from "pages/public/course/Courses"
import Workshop from "pages/public/workshop/Workshops"
import Careers from "pages/public/career/Careers"
import CareerDetail from "pages/public/career/CareerDetail"
import CourseDetail from "pages/public/course/CourseDetail"
import WorkshopDetail from "pages/public/workshop/WorkshopDetail"
import UserPrivateRouter from "../private-router/UserPrivateRouter"
import TransactionHistory from "pages/public/transaction-history/TransactionHistory"
import ResetPassword from "pages/public/forgot-password/ResetPassword"
import Fallback from "pages/public/forgot-password/Fallback"
import About from "pages/public/About"
import MyCourses from "pages/public/profile/MyCourses"
import MyWorkshops from "pages/public/profile/MyWorkshops"
import Settings from "pages/public/profile/Settings"
import ChangePassword from "pages/public/profile/ChangePassword"
import MyCourseDetail from "pages/public/profile/MyCourseDetail"
import MyWorkshopDetail from "pages/public/profile/MyWorkshopDetail"
import CareerUser from "pages/public/career/CareerUser"
import CheckoutIndex from "pages/public/checkout/CheckoutIndex"
import PaymentSuccess from "pages/payment/PaymentSuccess"
import PaymentFailed from "pages/payment/PaymentFailed"
import PaymentIndex from "pages/payment/PaymentIndex"
import Sertifikasi from "pages/public/sertifikasi/Sertifikasi"
import SertifikasiDetail from "pages/public/sertifikasi/SertifikasiDetail"
import SertifikasiForm from "pages/public/sertifikasi/SertifikasiForm"
import SertifikatDownload from "components/public/components/WorkshopSertifikat"
import CourseSertifikat from "components/public/components/CourseSertifikat"
import CourseGiftActivation from "pages/public/course/CourseGiftActivation"

const publicRoute: RouteObject = {
  path: "/",
  element: <GeneralPublicLayout />,
  children: [
    {
      index: true,
      element: <HomePage />,
    },
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "register",
      element: <Register />,
    },
    {
      path: "verify-user/:verify_token",
      element: <Verification />,
    },
    {
      path: "resend-verify-email",
      element: <ResendEmail />,
    },
    {
      path: "fallback",
      element: <VerificationFailed />,
    },
    {
      path: "success",
      element: <VerificationSuccess />,
    },
    {
      path: "forgot-password",
      element: <ForgotPassword />,
    },
    {
      path: "reset-password",
      children: [
        {
          path: ":token",
          element: <ResetPassword />,
        },
        {
          path: "invalid",
          element: <Fallback />,
        },
      ],
    },
    {
      path: "courses",
      children: [
        {
          index: true,
          element: <Courses />,
        },
        {
          path: ":slug",
          element: <CourseDetail />,
        },
        {
          path: "gift-activation/:gift_code_uuid",
          element: <CourseGiftActivation />
        }
      ],
    },
    {
      path: "payment",
      children: [
        {
          path: ":type/:slug/show/:token",
          element: <PaymentIndex />,
        },
        {
          path: "success",
          element: <PaymentSuccess />,
        },
        {
          path: "failed",
          element: <PaymentFailed />,
        },
      ],
    },
    {
      path: "about",
      element: <About />,
    },
    {
      path: "workshops",
      children: [
        {
          index: true,
          element: <Workshop />,
        },
        {
          path: ":slug",
          element: <WorkshopDetail />,
        },
      ],
    },
    {
      path: "sertifikasi",
      children: [
        {
          index: true,
          element: <Sertifikasi />,
        },
        {
          path: ":slug",
          element: <SertifikasiDetail />,
        },
        {
          path: "daftar",
          element: <SertifikasiForm />
        },
        {
          path: "daftar/:slug",
          element: <SertifikasiForm />
        },
      ],
    },

    {
      element: (
        <Suspense fallback={<div className="h-screen" />}>
          <UserPrivateRouter />
        </Suspense>
      ),
      children: [
        {
          element: <UserDashboardLayout />,
          children: [
            {
              path: "my-course",
              element: <MyCourses />,
            },
            {
              path: "my-workshop",
              element: <MyWorkshops />,
            },
            {
              path: "transaction-history",
              element: <TransactionHistory />,
            },
            {
              path: "settings",
              element: <Settings />,
            },
            {
              path: "settings/change-password",
              element: <ChangePassword />,
            },
          ],
        },
        {
          path: "my-workshop/:slug",
          element: <SertifikatDownload />,
        },
        {
          path: "my-workshop/sertifikat",
          element: <SertifikatDownload />,
        },
        {
          path: "my-course/:slug",
          element: <MyCourseDetail />,
        },
        {
          path: "my-course/sertifikat/:slug",
          element: <CourseSertifikat />,
        },
        {
          path: "checkout/:type/:slug",
          element: <CheckoutIndex />,
        },
        {
          path: "careers",
          children: [
            {
              index: true,
              element: <Careers />,
            },
            {
              path: ":slug",
              element: <CareerDetail />,
            },
            {
              path: "users/:slug",
              element: <CareerUser />,
            },
          ],
        },
      ],
    },
  ],
}

export default publicRoute
