import { useMemo, useRef } from "react"
import clsx from "clsx"

import CareerCard from "components/public/components/CareerCard"
import { useInfiniteGetCareers } from "utils/api/public/queries/hooks"
import CardHorizontalSkeleton from "components/public/components/CardHorizontalSkeleton"
import { Career } from "utils/types"

interface CategoryCareersProps {
  categoryIds: number[]
  filteredCategoryIds: number[]
  searchValue: string
}
const CategoryCareers: React.FC<CategoryCareersProps> = ({
  categoryIds = [],
  filteredCategoryIds = [],
  searchValue,
}) => {
  const componentRef = useRef<HTMLDivElement | null>(null)
  const {
    data: dataCareers,
    isLoading: isLoadingCareers,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteGetCareers({
    qParams: {
      category_ids: categoryIds,
      keyword: searchValue || undefined,
      limit: 10,
    },
  })

  const categorizedCareers = useMemo(() => {
    const categorize: Career[] = []

    dataCareers?.pages.forEach((page) => {
      page.data.forEach((career: Career) => {
        const careerCategoryIds = career.categories?.map(category => category.id)

        // filtering carrer that not include filtered category ids
        const intersectionWithFilteringCategoryIds = careerCategoryIds?.filter(
          categoryId => !filteredCategoryIds.includes(categoryId)
        )

        if (intersectionWithFilteringCategoryIds?.length) return;

        categorize.push(career)
      })
    })

    return categorize
  }, [dataCareers, filteredCategoryIds])

  const loadMore = () => {
    fetchNextPage()
  }

  return (
    <div
      ref={componentRef}
      className=" text-3xl flex flex-wrap gap-4 font-bold"
    >
      {categorizedCareers?.length > 0 && (
        <>
          <div
            className={clsx(
              "w-full",
              "lg:grid flex items-start justify-center flex-col lg:grid-cols-2 xl:grid-cols-3 gap-4 mt-3"
            )}
          >
            {isLoadingCareers
              ? [...Array(8)].map((_, index) => (
                <CardHorizontalSkeleton key={index} />
              ))
              : categorizedCareers?.length > 0 &&
              categorizedCareers.map((career) => (
                <CareerCard
                  key={career.id}
                  title={career.title}
                  slug={career.slug}
                  is_active={career.is_active}
                  description={career.description}
                  page={career.page}
                  location={career.location}
                  working_type={career.working_type}
                  company={career.company}
                  company_icon={career.company_icon}
                  employment_status={career.status_pekerjaan}
                  thumb_width="w-44"
                />
              ))}
          </div>

          {hasNextPage && (
            <div className="flex justify-center lg:justify-end mt-4 lg:mr-40">
              <button
                onClick={loadMore}
                className="text-primary text-lg cursor-pointer"
              >
                Load More
              </button>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default CategoryCareers
