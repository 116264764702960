import { useMutation } from "@tanstack/react-query"
import { GeneralMutationParams } from "utils/types"
import { CreateCourseGiftRequest, CreateCourseGiftResponse } from "utils/api/cms/types"
import createCourseGift from "../../functions/course/createCourseGift"

const useMutationCreateCourseGift = (
    options?: GeneralMutationParams<
        CreateCourseGiftResponse,
        CreateCourseGiftRequest
    >
) => {
    return useMutation({
        mutationFn: (payload) => createCourseGift(payload),
        ...options
    })
}

export default useMutationCreateCourseGift