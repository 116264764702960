import Typography from "components/ui/typography"
import CourseGiftForm, { CourseGiftFormType } from "./CourseGiftForm"
import { SubmitHandler } from "react-hook-form"

const CourseGift: React.FC<{
  onSubmit: SubmitHandler<CourseGiftFormType>,
  isLoading: boolean,
  buttonClass: string,
  buttonText?: string,
  formType?: 'first' | 'second',
}> = ({
  formType = 'first',
  buttonText = "Tambahkan",
  ...props
}) => {
    return (
      <>
        {formType == 'first' &&
          <Typography
            variant="heading6"
            as="h2"
            className="mb-4"
          >
            Course Gift
          </Typography>
        }
        <CourseGiftForm
          formType={formType}
          onSubmit={props.onSubmit}
          isLoading={props.isLoading}
          buttonClass={props.buttonClass}
          buttonText={buttonText}
        />
      </>
    )
  }

export default CourseGift