import { redirect } from "react-router-dom"
import { RouteObject } from "react-router-dom"

import EmployeeDashboardLayout from "components/layouts/EmployeeDashboardLayout"
import AdminLoginPage from "pages/cms/Login"
import { getCmsToken } from "utils/auth"

/** Course */
import CourseManagement from "pages/cms/course/CourseManagement"
import { loadCourses as coursesLoader } from "pages/cms/course/CourseManagement"
import CourseManagementCreate, {
  loadCategoryOptions,
} from "pages/cms/course/CourseManagementCreate"
import CourseCategoryManagement, {
  loadCourseCategories as courseCategoryLoader,
} from "pages/cms/course/CourseCategoryManagement"
import CourseCategoryManagementCreate from "pages/cms/course/CourseCategoryManagementCreate"
import CourseManagementEdit, {
  loadCourse as courseEditLoader,
} from "pages/cms/course/CourseManagementEdit"
import CourseSectionManagement, {
  courseSectionLoader,
} from "pages/cms/course/CourseSectionManagement"
import CourseSectionManagementCreate, {
  courseSectionCreateLoader,
} from "pages/cms/course/CourseSectionManagementCreate"
import CourseSectionManagementEdit, {
  courseSectionEditLoader,
} from "pages/cms/course/CourseSectionManagementEdit"
import CourseVideoManagement, {
  courseVideoManagementLoader,
} from "pages/cms/course/CourseVideoManagement"
import CourseVideoManagementCreate from "pages/cms/course/CourseVideoManagementUpload"

/** Webinar */
import WebinarManagement from "pages/cms/webinar/WebinarManagement"
import { loadWebinars as webinarLoader } from "pages/cms/webinar/WebinarManagement"
import WebinarManagementCreate from "pages/cms/webinar/WebinarManagementCreate"
import WebinarCategoryManagement, {
  loadWebinarCategories as webinarCategoriesLoader,
} from "pages/cms/webinar/WebinarCategoryManagement"
import WebinarCategoryManagementCreate from "pages/cms/webinar/WebinarCategoryManagementCreate"
import WebinarCategoryManagementEdit, {
  loadWebinarCategory as webinarCategoryEditLoader,
} from "pages/cms/webinar/WebinarCategoryManagementEdit"
import WebinarManagementEdit, {
  loadWebinarEdit as webinarEditLoader,
} from "pages/cms/webinar/WebinarManagementEdit"
import WebinarManagementDetail from "pages/cms/webinar/WebinarManagementDetail"

/** Referral */
import ReferralManagement, {
  referralManagementLoader,
} from "pages/cms/referral/ReferralManagement"
import ReferralManagementCreate, {
  referralCreateLoader,
} from "pages/cms/referral/ReferralManagementCreate"
import TransactionReferralListManagement, {
  transactionReferralManagementLoader,
} from "pages/cms/referral/ReferralTransactionUser"
import Settings from "pages/cms/settings"

/** Discount */
import DiscountManagement from "pages/cms/discount/DiscountManagement"
import DiscountCreate from "pages/cms/discount/DiscountCreate"
import DiscountUpdate from "pages/cms/discount/DiscountUpdate"

/** Settings */
import ChangePassword from "pages/cms/settings/ChangePassword"
import CreateEmployee from "pages/cms/settings/CreateEmployee"
import SuperAdminPrivateRouter from "../private-router/SuperAdminPrivateRouter"
import CareerCategoryManagement from "pages/cms/career/CareerCategoryManagement"
import CareerCategoryManagementCreate from "pages/cms/career/CareerCategoryManagementCreate"
import CareerCategoryEdit from "pages/cms/career/CareerCategoryEdit"
import CareerManagement from "pages/cms/career/CareerManagement"
import CareerManagementCreate from "pages/cms/career/CareerManagementCreate"
import CareerManagementEdit from "pages/cms/career/CareerManagementEdit"
import CareerManagementDetail from "pages/cms/career/CareerManagementDetail"

/** User List */
import UserManagement from "pages/cms/user/UserManagement"
import UserManagementDetail from "pages/cms/user/UserManagementDetail"
import CourseVideoDetail from "pages/cms/course/CourseVideoDetail"
import CourseVideoEdit from "pages/cms/course/CourseVideoEdit"
import ReferralUpdate from "pages/cms/referral/ReferralUpdate"
import UserTransactions from "pages/cms/user/UserTransactions"
import CourseCategoryUpdate from "pages/cms/course/CourseCategoryUpdate"
import UserTransactionDetail from "pages/cms/user/UserTransactionDetail"
import CareerManagementUsers from "pages/cms/career/CareerManagementUsers"
import Dashboard from "pages/cms/Dashboard"
import PartnershipManagement from "pages/cms/partnership/PartnershipManagement"
import PartnershipManagementCreate from "pages/cms/partnership/PartnershipManagementCreate"
import PartnershipManagementUpdate from "pages/cms/partnership/PartnershipManagementUpdate"
import SlideManagement from "pages/cms/slide/SlideManagement"
import SlideCreate from "pages/cms/slide/SlideCreate"
import SertifikatManagement from "pages/cms/sertifikat/SertifikatManagement"
import SertifikatCreate from "pages/cms/sertifikat/SertifikatCreate"
import SertifikatEdit from "pages/cms/sertifikat/SertifikatEdit"
import SertifikatDetail from "pages/cms/sertifikat/SertifikatDetail"
import SertifikatCreateProgram from "pages/cms/sertifikat/SertifikatCreateProgram"
import SertifikatPeserta from "pages/cms/sertifikat/SertifikatPeserta"
import SertifikatPesertaEdit from "pages/cms/sertifikat/SertifikatPesertaEdit"
import SertifikatDocument from "pages/cms/sertifikat/SertifikatDocument"
import SertifikatDoucmentCreate from "pages/cms/sertifikat/SertifikatDocumentCreate"
import WebinarManagementPeserta from "pages/cms/webinar/WebinarManagementPeserta"
import UserCourseDetail from "pages/cms/user/UserCourseDetail"
import UserGiveaway from "pages/cms/user/UserGiveaway"
import CourseCategoryQuestionManagement from "pages/cms/course/quiz/CourseCategoryQuestionManagement"
import path from "path"
import CourseCategoryQuestionCreate from "pages/cms/course/quiz/CourseCategoryQuestionCreate"
import CourseQuiestionManagements from "pages/cms/course/quiz/question/CourseQuestionManagements"
import CourseQuestionCreate from "pages/cms/course/quiz/question/CourseQuestionCreate"
import ChoiseQuestionManagement from "pages/cms/course/quiz/choice/ChoiseQuestionManagement"
import ChoiceQuestionCreate from "pages/cms/course/quiz/choice/ChoiceQuestionCreate"
import CourseRedemptionCodeManagement, { loadCourseRedemptionCodes } from "pages/cms/course/redemption-code/CourseRedemptionCodeManagement"
import CourseRedemptionCodeCreate from "pages/cms/course/redemption-code/CourseRedemptionCodeCreate"
import CourseGiftManagement, { loadCourseGifts } from "pages/cms/course/gift/CourseGiftManagement"
import CourseGiftCreate from "pages/cms/course/gift/CourseGiftCreate"

const cmsRoute: RouteObject = {
  path: "cms",
  children: [
    {
      path: "login",
      element: <AdminLoginPage />,
      loader: () => {
        const token = getCmsToken()
        if (token) return redirect("/cms/career")

        return null
      },
    },
    {
      element: <EmployeeDashboardLayout />,
      loader: () => {
        const token = getCmsToken()
        if (!token) return redirect("/")

        return null
      },
      children: [
        {
          path: "settings",
          element: <Settings />,
        },
        {
          path: "dashboard",
          element: <Dashboard />,
        },
        {
          path: "settings/change-password",
          element: <ChangePassword />,
        },
        {
          element: <SuperAdminPrivateRouter />,
          children: [
            {
              path: "settings/create-employee",
              element: <CreateEmployee />,
            },
          ],
        },
        {
          path: "course",
          children: [
            {
              index: true,
              element: <CourseManagement />,
              loader: coursesLoader,
            },
            {
              path: "create",
              element: <CourseManagementCreate />,
              loader: loadCategoryOptions,
            },
            {
              path: "edit/:id",
              element: <CourseManagementEdit />,
              loader: courseEditLoader,
            },
            {
              path: "category-question/:id",
              children: [
                {
                  index: true,
                  element: <CourseCategoryQuestionManagement />,
                },
                {
                  path: "create",
                  element: <CourseCategoryQuestionCreate />,
                },
                {
                  path: "detail/:id",
                  children: [
                    {
                      index: true,
                      element: <CourseQuiestionManagements />,

                    },
                    {
                      path: "create",
                      element: <CourseQuestionCreate />,
                    },
                    {
                      path: "detail-choice/:id",
                      children: [
                        {
                          index: true,
                          element: <ChoiseQuestionManagement />,
                        },
                        {
                          path: 'create',
                          element: <ChoiceQuestionCreate />
                        }
                      ]
                    },
                  ]
                },
              ]
            },
            {
              path: "detail/:id",
              children: [
                {
                  index: true,
                  element: <CourseSectionManagement />,
                  loader: courseSectionLoader,
                },
                {
                  path: "create",
                  element: <CourseSectionManagementCreate />,
                  loader: courseSectionCreateLoader,
                },
                {
                  path: "edit/:sectionId",
                  element: <CourseSectionManagementEdit />,
                  loader: courseSectionEditLoader,
                },
                {
                  path: "detail/:sectionID",
                  children: [
                    {
                      index: true,
                      element: <CourseVideoManagement />,
                      loader: courseVideoManagementLoader,
                    },
                    {
                      path: "upload",
                      element: <CourseVideoManagementCreate />,
                    },
                  ],
                },
                {
                  path: "detail/:sectionID/edit-video/:videoID",
                  children: [
                    {
                      index: true,
                      element: <CourseVideoEdit />,
                    },
                  ],
                },
                {
                  path: "detail/:sectionID/detail-video/:videoID",
                  children: [
                    {
                      index: true,
                      element: <CourseVideoDetail />,
                    },
                  ],
                },
              ],
            },
            {
              path: "redemption-codes/:id",
              children: [
                {
                  index: true,
                  element: (
                    <CourseRedemptionCodeManagement />
                  ),
                  loader: loadCourseRedemptionCodes,
                },
                {
                  path: 'create',
                  element: <CourseRedemptionCodeCreate />
                }
              ]
            },
            {
              path: "gifts/:id",
              children: [
                {
                  index: true,
                  element: <CourseGiftManagement />,
                  loader: loadCourseGifts,
                },
                {
                  path: 'create',
                  element: <CourseGiftCreate />
                }
              ]
            }
          ],
        },
        {
          path: "slide",
          children: [
            {
              index: true,
              element: <SlideManagement />,
            },
            {
              path: "create",
              element: <SlideCreate />,
            },
          ],
        },
        {
          path: "webinar",
          children: [
            {
              index: true,
              element: <WebinarManagement />,
              loader: webinarLoader,
            },
            {
              path: "create",
              element: <WebinarManagementCreate />,
              loader: webinarCategoriesLoader,
            },
            {
              path: "edit/:id",
              element: <WebinarManagementEdit />,
              loader: webinarEditLoader,
            },
            {
              path: "detail/:id",
              element: <WebinarManagementDetail />,
            },
            {
              path: "peserta/:id",
              element: <WebinarManagementPeserta />,
            },
          ],
        },
        {
          path: "user",
          children: [
            {
              index: true,
              element: <UserManagement />,
            },
            {
              path: "detail/:id",
              element: <UserManagementDetail />,
            },
            {
              path: "transactionhistories",
              element: <UserTransactions />,
            },
            {
              path: "transactionhistories/:trx_id",
              element: <UserTransactionDetail />,
            },
            {
              path: "course/:id",
              element: <UserCourseDetail />,
            },
            {
              path: "course/:id/create",
              element: <UserGiveaway />,
            },
          ],
        },
        {
          path: "career",
          children: [
            {
              index: true,
              element: <CareerManagement />,
            },
            {
              path: "create",
              element: <CareerManagementCreate />,
            },
            {
              path: "edit/:id",
              element: <CareerManagementEdit />,
            },
            {
              path: "detail/:id",
              element: <CareerManagementDetail />,
            },
            {
              path: "peserta/:id",
              element: <CareerManagementUsers />,
            },
          ],
        },
        {
          path: "partnership",
          children: [
            {
              index: true,
              element: <PartnershipManagement />,
            },
            {
              path: "create",
              element: <PartnershipManagementCreate />,
            },
            {
              path: "edit/:id",
              element: <PartnershipManagementUpdate />,
            },
          ],
        },
        {
          path: "sertifikat",
          children: [
            {
              index: true,
              element: <SertifikatManagement />,
            },
            {
              path: "create",
              element: <SertifikatCreate />,
            },
            {
              path: "edit/:id",
              element: <SertifikatEdit />,
            },
            {
              path: "detail/:id",
              element: <SertifikatDetail />,
            },
            {
              path: "detail/:id/create",
              element: <SertifikatCreateProgram />,
            },
            {
              path: "peserta/:id",
              element: <SertifikatPeserta />,
            },
            {
              path: "peserta/:id_sertifikat/edit/:id",
              element: <SertifikatPesertaEdit />,
            },
            {
              path: "document/:id",
              element: <SertifikatDocument />,
            },
            {
              path: "document/:id/create",
              element: <SertifikatDoucmentCreate />,
            },
          ],
        },
        {
          path: "master",
          children: [
            {
              path: "course-category",
              children: [
                {
                  index: true,
                  element: <CourseCategoryManagement />,
                  loader: courseCategoryLoader,
                },
                {
                  path: "create",
                  element: <CourseCategoryManagementCreate />,
                },
                {
                  path: "edit/:id",
                  element: <CourseCategoryUpdate />,
                },
              ],
            },
            {
              path: "webinar-category",
              children: [
                {
                  index: true,
                  element: <WebinarCategoryManagement />,
                  loader: webinarCategoriesLoader,
                },
                {
                  path: "create",
                  element: <WebinarCategoryManagementCreate />,
                },
                {
                  path: "edit/:id",
                  element: <WebinarCategoryManagementEdit />,
                  loader: webinarCategoryEditLoader,
                },
              ],
            },
            {
              path: "career-category",
              children: [
                {
                  index: true,
                  element: <CareerCategoryManagement />,
                },
                {
                  path: "create",
                  element: <CareerCategoryManagementCreate />,
                },
                {
                  path: "edit/:id",
                  element: <CareerCategoryEdit />,
                },
              ],
            },
            {
              path: "referral",
              children: [
                {
                  index: true,
                  element: <ReferralManagement />,
                  loader: referralManagementLoader,
                },
                {
                  path: "transaction/:id",
                  element: <TransactionReferralListManagement />,
                  loader: transactionReferralManagementLoader,
                },
                {
                  path: "create",
                  element: <ReferralManagementCreate />,
                  loader: referralCreateLoader,
                },
                {
                  path: "edit/:id",
                  element: <ReferralUpdate />,
                },
              ],
            },
            {
              path: "discount",
              element: <DiscountManagement />,
            },
            {
              path: "discount/create",
              element: <DiscountCreate />,
            },
            {
              path: "discount/edit/:id",
              element: <DiscountUpdate />,
            },
          ],
        },
      ],
    },
  ],
}

export default cmsRoute
