import { Link } from "react-router-dom"
import { CSSProperties } from "react"

import clsxm from "utils/clsxm"

interface CardContainerProps {
  to?: string
  scale?: boolean
  height?: number
  width?: number
  maxHeight?: number
  maxWidth?: number
  className?: string
  children: React.ReactNode
}

const CardContainer = ({
  to,
  scale,
  height,
  width,
  maxHeight,
  maxWidth,
  className,
  children,
}: CardContainerProps) => {
  if (!!to) {
    return (
      <Link
        style={
          {
            "--height": !!height && height + "px",
            "--width": !!width && width + "px",
            "--maxHeight": !!maxHeight && maxHeight + "px",
            "--maxWidth": !!maxWidth && maxWidth + "px",
          } as CSSProperties
        }
        className={clsxm(
          "relative",
          "h-fit w-fit",
          "object-cover object-center",
          "rounded-lg",
          "shadow-lg", // Enhanced shadow for better visibility
          "mb-3", // Margin bottom for mobile
          !!scale && "hover:scale-105 transition-transform",
          !!height && "h-[--height]",
          !!width && "w-[--width]",
          !!maxHeight && "h-[--maxHeight]",
          !!maxWidth && "w-[--maxWidth]",
          className
        )}
        to={to}
      >
        {children}
      </Link>
    )
  }

  return (
    <div
      style={
        {
          "--height": !!height && height + "px",
          "--width": !!width && width + "px",
          "--maxHeight": !!maxHeight && maxHeight + "px",
          "--maxWidth": !!maxWidth && maxWidth + "px",
        } as CSSProperties
      }
      className={clsxm(
        "relative",
        "h-fit w-fit",
        "object-cover object-center",
        "rounded-lg",
        "shadow",
        !!scale && "hover:scale-105 transition-transform",
        !!height && "h-[--height]",
        !!width && "w-[--width]",
        !!maxHeight && "h-[--maxHeight]",
        !!maxWidth && "w-[--maxWidth]",
        className
      )}
    >
      {children}
    </div>
  )
}

export default CardContainer
