import { ChevronLeftIcon, ChevronRightIcon, DocumentPlusIcon } from "@heroicons/react/24/solid"
import { createColumnHelper } from "@tanstack/react-table"
import to from "await-to-js"
import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "components/ui/breadcrumb/DefBreadcrumb"
import ButtonWithIcon from "components/ui/button/ButtonWithIcon"
import DarkPagination from "components/ui/pagination/DarkPagination"
import ReactTable from "components/ui/react-table/ReactTable"
import usePagination from "hooks/usePagination"
import moment from "moment"
import { useEffect, useState } from "react"
import { LoaderFunction, useLoaderData, useNavigate, useParams } from "react-router-dom"
import { CourseGift } from "utils/api/cms/types"
import api from "utils/axiosInstance"
import { getCMSSegments } from "utils/helper"
import { GeneralApiResponse } from "utils/types"

const CourseGiftManagement: React.FC = () => {
  const response = useLoaderData() as GeneralApiResponse<CourseGift[]>
  const segments = getCMSSegments()
  const navigate = useNavigate()
  const { id } = useParams()

  const [keyword, setKeyword] = useState("")
  const [perPage, setPerpage] = useState(10)

  const [courseGiftsResponse, setCourseGiftsResponse] = useState<GeneralApiResponse<CourseGift[]>>()

  const courseGiftsFetcher = async () => {
    const page = Math.min(currentPage + 1, totalPages)
    const [_, resultResponse] = await to<GeneralApiResponse<CourseGift[]>>(
      api.cms.getCourseGifts({
        page: page,
        keyword: keyword,
        limit: perPage,
        course_id: Number(id),
      })
    )

    setTotalPages(resultResponse!.meta!.last_page)
    setCourseGiftsResponse(resultResponse)
  }

  const {
    totalPages,
    currentPage,
    initialPage,
    setTotalPages,
    setCurrentPage,
    setInitialPage,
    setShouldFetch,
    setForced,
  } = usePagination(courseGiftsFetcher, [keyword, perPage])

  useEffect(() => {
    if (response) {
      setCourseGiftsResponse(response)
      setTotalPages(response.meta!.last_page)

      if (response.meta!.current_page > 1) {
        setInitialPage(response.meta!.current_page - 1)
      }

      setShouldFetch(true)
    }
  }, [response])

  const colHelper = createColumnHelper<CourseGift>()
  const courseGiftColumns = [
    colHelper.accessor('recipient_email', {
      header: () => 'Email Penerima'
    }),
    colHelper.accessor("senderable.email", {
      header: () => "Email Pengirim"
    }),
    colHelper.accessor('remarks', {
      header: () => "Catatan",
    }),
    colHelper.accessor("created_at", {
      header: () => "Dibuat pada",
      cell: (props) => {
        const value = props.getValue()
        return moment(value).format('DD-MM-YYYY')
      }
    }),
    colHelper.accessor("claimed_at", {
      header: () => "Diklaim pada",
      cell: (props) => {
        const value = props.getValue()
        if (!value) {
          return (
            <span className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded bg-yellow-900 text-yellow-300">
              Belum Diklaim
            </span>
          )
        }

        return moment(value).format('DD-MM-YYYY')
      }
    }),
  ]

  return (
    <EmployeeDashboardWrapper>
      <DefBreadcrumb
        segments={segments}
        prefix={"cms"}
      />
      <h2 className="text-xl mt-4 text-primary font-semibold leading-loose tracking-wide">
        Pengaturan Gift Course
      </h2>
      <ButtonWithIcon
        action={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.preventDefault()
          return navigate("create")
        }}
        type="button"
        class="ml-auto border-2 border-transparent bg-primary text-white space-x-2 hover:bg-transparent hover:text-white hover:border-primary"
      >
        <DocumentPlusIcon className="h-6 w-6 text-white" />
        <span>Buat Gift lainnya</span>
      </ButtonWithIcon>
      {courseGiftsResponse &&
        <>
          <ReactTable
            data={courseGiftsResponse!.data}
            columns={courseGiftColumns}
          />
          <div className="flex pb-8 items-center ml-auto">
            <DarkPagination
              nexLabel={<ChevronRightIcon className="h-5 w-5 text-gray-400" />}
              previousLabel={
                <ChevronLeftIcon className="h-5 w-5 text-gray-400" />
              }
              pageCount={totalPages}
              pageRangeDisplayed={2}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
              initialPage={initialPage}
            />
          </div>
        </>
      }
    </EmployeeDashboardWrapper>
  )
}

export default CourseGiftManagement

export const loadCourseGifts: LoaderFunction = async ({ params }): Promise<
  GeneralApiResponse<CourseGift[]>
> => {
  const { id } = params
  const response = await api.cms.getCourseGifts({
    course_id: Number(id),
  })

  return response;
}