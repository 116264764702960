import { SubmitHandler, useForm, Controller } from "react-hook-form"
import { toast } from "react-toastify"
import { useNavigate, useParams } from "react-router-dom"
import ButtonWithIcon from "components/ui/button/ButtonWithIcon"
import TextInput from "components/ui/form/TextInput"
import { useMutationCreateQuestion } from "utils/api/cms/mutations/hooks"
import { GenerateCourseRedemptionCodePayload } from "utils/api/cms/types"
import {
  BookmarkIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid"
import useMutationGenerateCourseRedemptionCode from "utils/api/cms/mutations/hooks/course/useMutationGenerateCourseRedemptionCode"

const RedemptionCodeForm = (props) => {
  const navigate = useNavigate()
  const params = useParams()
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<GenerateCourseRedemptionCodePayload>({})

  const { mutate: mutateCreate } =
    useMutationGenerateCourseRedemptionCode({
      onSuccess(data) {
        if (!data.success) {
          return
        }
        toast.success("redeem code berhasil dibuat")
        setTimeout(() => {
          navigate("/cms/course/redemption-codes/" + Number(params.id))
        }, 1000)
      },
      onError(error) {
        toast.error(error.message)
      },
    })

  const onSubmit: SubmitHandler<GenerateCourseRedemptionCodePayload> = (data) => {
    const payload: GenerateCourseRedemptionCodePayload = {
      amount: data.amount,
    };

    return mutateCreate({
      id: Number(params.id),
      payload,
    });
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-4 pb-4 min-w-[42rem]"
    >
      <TextInput
        type="text"
        className="cms-base-input mt-2"
        labelClass="cms-base-label mt-4"
        isRequired
        label="Banyaknya"
        errorMessage={errors.amount?.message}
        errorIcon={
          <ExclamationTriangleIcon
            height={24}
            width={24}
            className="text-red-800"
          />
        }
        {...register("amount", {
          required: {
            value: true,
            message: "Pertanyaan wajib diisi",
          },
          min: {
            value: 0,
            message: "Banyaknya minimum 0"
          },
          max: {
            value: 24,
            message: "Maksimum 24"
          }
        })}
      />
      <ButtonWithIcon
        action={undefined}
        type="submit"
        class="self-start mt-12 border-2 border-transparent bg-primary text-white space-x-2 hover:bg-transparent hover:text-white hover:border-primary"
        disabled={isSubmitting}
      >
        <BookmarkIcon className="h-6 w-6 text-white" />
        <span>
          {!isSubmitting ? (
            <>{"Tambahkan"}</>
          ) : (
            "Menyimpan..."
          )}
        </span>
      </ButtonWithIcon>
    </form>
  )
}

export default RedemptionCodeForm
