import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "components/ui/breadcrumb/DefBreadcrumb"
import { getCMSSegments } from "utils/helper"
import API from "utils/axiosInstance"
import { LoaderFunction, useLoaderData, useNavigate, useParams } from "react-router-dom"
import { CMSCourseRedemptionCodeListResponse, CourseRedemptionCode } from "utils/types"
import { useEffect, useState } from "react"
import { createColumnHelper } from "@tanstack/react-table"
import ReactTable from "components/ui/react-table/ReactTable"
import usePagination from "hooks/usePagination"
import to from "await-to-js"
import DarkPagination from "components/ui/pagination/DarkPagination"
import { ChevronLeftIcon, ChevronRightIcon, DocumentPlusIcon } from "@heroicons/react/24/solid"
import ButtonWithIcon from "components/ui/button/ButtonWithIcon"


const CourseRedemptionCodeManagement: React.FC = () => {
  const response = useLoaderData() as CMSCourseRedemptionCodeListResponse<CourseRedemptionCode>
  const segments = getCMSSegments()
  const navigate = useNavigate()
  const { id } = useParams()
  const [keyword, setKeyword] = useState("")
  const [perPage, setPerpage] = useState(10)


  const [courseRedemptionCodesResponse, setCourseRedemptionCodesResponses]
    = useState<CMSCourseRedemptionCodeListResponse<CourseRedemptionCode>>()

  const courseRedemptionCodeFetcher = async () => {
    const page = Math.min(currentPage + 1, totalPages)
    const [_, resultResponse] = await to<CMSCourseRedemptionCodeListResponse<CourseRedemptionCode>>(
      API.cms.getRedemptionCodesByCourse(Number(id), {
        page: page,
        keyword: keyword,
        limit: perPage,
      })
    )

    setTotalPages(resultResponse!.meta!.last_page)
    setCourseRedemptionCodesResponses(resultResponse)
  }

  const {
    totalPages,
    currentPage,
    initialPage,
    setTotalPages,
    setCurrentPage,
    setInitialPage,
    setShouldFetch,
    setForced,
  } = usePagination(courseRedemptionCodeFetcher, [keyword, perPage])

  useEffect(() => {
    if (response) {
      setCourseRedemptionCodesResponses(response)
      setTotalPages(response.meta!.last_page)

      if (response.meta!.current_page > 1) {
        setInitialPage(response.meta!.current_page - 1)
      }

      setShouldFetch(true)
    }
  }, [response])

  const colHelper = createColumnHelper<CourseRedemptionCode>()
  const courseRedemptionCodeColumns = [
    colHelper.accessor('redemption_code', {
      header: () => "Redeem Code"
    }),
    colHelper.accessor('remaining_claims', {
      header: () => "Tersisa sebanyak",
      cell: (props) => {
        if (!props.getValue()) {
          return (
            <span className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded bg-yellow-900 text-yellow-300">
              Habis: {props.getValue()}
            </span>
          )
        }

        return (
          <span className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded bg-green-900 text-green-300">
            Tersisa: {props.getValue()}
          </span>
        )
      }
    }),
    colHelper.accessor('is_active', {
      header: () => "Status Keaktifan",
      cell: (props) => {
        if (!props.getValue()) {
          return (
            <span className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded bg-yellow-900 text-yellow-300">
              Pending
            </span>
          )
        }

        return (
          <span className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded bg-green-900 text-green-300">
            Aktif
          </span>
        )
      }
    })
  ];

  return (
    <EmployeeDashboardWrapper>
      <DefBreadcrumb
        segments={segments}
        prefix={"cms"}
      />
      <h2 className="text-xl mt-4 text-primary font-semibold leading-loose tracking-wide">
        Pengaturan Redeem Code Course
      </h2>
      <ButtonWithIcon
        action={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.preventDefault()
          return navigate("create")
        }}
        type="button"
        class="ml-auto border-2 border-transparent bg-primary text-white space-x-2 hover:bg-transparent hover:text-white hover:border-primary"
      >
        <DocumentPlusIcon className="h-6 w-6 text-white" />
        <span>Tambah Redeem Code</span>
      </ButtonWithIcon>
      {courseRedemptionCodesResponse &&
        <>
          <ReactTable
            data={courseRedemptionCodesResponse!.data}
            columns={courseRedemptionCodeColumns}
          />
          <div className="flex pb-8 items-center ml-auto">
            <DarkPagination
              nexLabel={<ChevronRightIcon className="h-5 w-5 text-gray-400" />}
              previousLabel={
                <ChevronLeftIcon className="h-5 w-5 text-gray-400" />
              }
              pageCount={totalPages}
              pageRangeDisplayed={2}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
              initialPage={initialPage}
            />
          </div>
        </>
      }
    </EmployeeDashboardWrapper>
  )
}

export default CourseRedemptionCodeManagement

export const loadCourseRedemptionCodes: LoaderFunction = async ({ params }): Promise<
  CMSCourseRedemptionCodeListResponse<CourseRedemptionCode>
> => {
  const { id } = params
  const searchParams = new URLSearchParams(window.location.search)
  const response = await API.cms.getRedemptionCodesByCourse(Number(id), {
    keyword: searchParams.has("search")
      ? (searchParams.get("search") as string)
      : "",
    page: searchParams.has("page") ? +(searchParams.get("page") as string) : 1,
    limit: 10,
  })

  return response
}
