import { UserIcon } from "@heroicons/react/20/solid"
import { Tooltip } from "flowbite-react"

import Typography from "components/ui/typography"
import { ProductCategory } from "utils/types"
import moment from "moment"

interface TitleProps {
  title: string
  categories: ProductCategory[]
  startDate: string
  endDate: string
}

const WorkshopTitle = ({
  title,
  categories,
  startDate,
  endDate
}: TitleProps) => {

  const workshopStatus = () => {
    const now = moment(moment.now());
    now.startOf('day');

    const start = moment(startDate);
    start.startOf('day');

    const end = moment(endDate);
    end.endOf('day');

    if (now.isBefore(start)) {
      return 'Upcoming'
    } else if (now.isBetween(start, end)) {
      return "Sedang berlangsung"
    } else {
      return "Workshop telah berakhir"
    }
  };

  return (
    <div className="lg:w-2/3">
      <Typography
        variant="subheading2"
        className="mb-2"
      >
        {title}
      </Typography>
      <div className="flex flex-wrap gap-2 w-full">
        <Typography
          variant="text3"
          className="font-medium p-2 bg-red-600 text-white rounded-md"
        >
          {workshopStatus()}
        </Typography>
        {categories.map((category) => (
          <Typography
            key={category.id}
            variant="text3"
            className="font-medium p-2 bg-gray-200 text-gray-600 rounded-md"
          >
            {category.name}
          </Typography>
        ))}
      </div>
    </div>
  )
}

export default WorkshopTitle
