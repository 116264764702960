import { UseQueryOptions } from "@tanstack/react-query"
import {
  GeneralApiResponse,
  GeneralQueryParams,
  ProductType,
} from "utils/types"

export enum TransactionQueryKey {
  Transaction = "transaction",
}

export interface giftProduct {
  recipient_email?: string
  remarks?: string
}

export interface BuyProductRequest {
  buyable: string
  type: ProductType
  referral_code?: string
  is_giveaway?: boolean
  gift?: giftProduct
}

export interface BuyProductData {
  transaction_id: string
  payment_token: string
}

export type BuyProductResponse = Omit<
  GeneralApiResponse<BuyProductData>,
  "links" | "meta"
>

export interface Transactionable {
  id: number
  title: string
  slug: string
}

export interface TransactionDetail {
  id: number
  transactionable_type: ProductType
  original_price: string
  price: string
  transactionable: Transactionable
}

export interface Buyer {
  id: number
  email: string
  first_name: string
}

export interface TransactionData {
  id: number
  trx_id: string
  amount: string
  status: string
  snap_token: string
  external_status: string
  paid_at: string | null
  created_at: string
  updated_at: string
  transaction_detail: TransactionDetail
  buyer: Buyer
}

export type GetTransactionByIdParams = Omit<
  GeneralQueryParams<UseQueryOptions<GetTransactionByIdResponse>>,
  "qParams"
> & {
  id: string
}

export type GetTransactionByIdResponse = Omit<
  GeneralApiResponse<TransactionData>,
  "links" | "meta"
>

export interface CallbackPaymentMidtransRequest {
  status_code?: string
  status_message?: string
  transaction_id?: string
  order_id?: string
  gross_amount?: string
  payment_type?: string
  transaction_time?: string
  transaction_status?: string
  fraud_status?: string
}

export interface CallbackPaymentMidtrans {
  status_code?: string
  status_message?: string
  transaction_id?: string
  order_id?: string
  gross_amount?: string
  payment_type?: string
  transaction_time?: string
  transaction_status?: string
  fraud_status?: string
}

export type CallbackPaymentMidtransResponse = Omit<
  GeneralApiResponse<CallbackPaymentMidtrans>,
  "links" | "meta"
>