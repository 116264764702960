import Typography from "components/ui/typography"
import ReedemCourseForm from "./RedeemCourseForm"

const RedeemCourse: React.FC<{ course_id: number, buyable?: boolean }> = (props) => {
  return (
    <>
      <Typography
        variant="heading6"
        as="h2"
        className="mb-4"
      >
        Redeem Course
      </Typography>
      <ReedemCourseForm course_id={props.course_id} buyable={props.buyable} />
    </>
  )
}

export default RedeemCourse