import { Helmet } from "react-helmet"

import ListWorkshop from "components/public/workshop/list-workshop"
import Section from "components/ui/section/Section"
import ResponsiveImage from "components/ui/responsive-image"
import clsxm from "utils/clsxm"

import bannerDesktop from "assets/images/banner/workshop-banner-desktop.jpeg"
import bannerMobile from "assets/images/banner/workshop-banner-mobile.jpeg"


const Workshops = () => {
  return (
    <>
      <Helmet>
        <title>Workshops</title>
      </Helmet>
      <div className="container">
        <div className={clsxm("mt-8 lg:text-left ")}>
          <ResponsiveImage
            src={bannerDesktop}
            sources={[
              {
                maxHeader: '(max-width: 768px)',
                responsiveSrc: bannerMobile
              }
            ]}
          />
        </div>
        <Section
          className="my-12"
        >
          <ListWorkshop />
        </Section>
      </div>
    </>
  )
}

export default Workshops
