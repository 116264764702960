import { Link } from "react-router-dom"
import { UserIcon } from "@heroicons/react/20/solid"
import noImage from "assets/images/no-image.jpg"

import Card from "components/ui/card/vertical"
import Typography from "components/ui/typography"
import Button from "components/ui/button/Button"
import { Badge } from "flowbite-react"
import clsx from "clsx"
import { useEffect } from "react"

interface WorkshopCardProps {
  quota: number
  title: string
  startDate: string
  endDate: string
  slug: string
  currency?: string
  masterPrice: string
  currentPrice: string
  imgSrc?: string
  width?: number
  height?: number
  className?: string
  speakersData: string
}

const WorkshopCard = ({
  quota,
  title,
  startDate,
  endDate,
  slug,
  currency,
  masterPrice,
  currentPrice,
  speakersData,
  imgSrc,
  width,
  height,
  className,
}: WorkshopCardProps) => {
  const masterPriceNumeric = parseFloat(masterPrice)
  const currentPriceNumeric = parseFloat(currentPrice)
  const isDiscount = masterPriceNumeric !== currentPriceNumeric
  const discountPercentage = isDiscount
    ? ((masterPriceNumeric - currentPriceNumeric) / masterPriceNumeric) * 100
    : 0

  const speakersObj = JSON.parse(speakersData);
  const speakersArray = speakersObj?.speakers ?? [];

  const workshopStatus = () => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const start = new Date(startDate);
    start.setHours(0, 0, 0, 0);
    const end = new Date(endDate);
    end.setHours(23, 59, 59, 999);
    if (now < start) {
      const diffDays = Math.round((start.getTime() - now.getTime()) / (1000 * 60 * 60 * 24));
      return `${diffDays} hari lagi`;
    } else if (now >= start && now <= end) {
      return "Sedang berlangsung";
    } else {
      return "Workshop telah berakhir";
    }
  };

  return (
    <Card
      to={`/workshops/${slug}`}
      imgSrc={imgSrc ?? noImage}
      imgAlt={title}
      scale
      className={`${className} w-full`}
    >
      <div>
        <Card.Title
          as="p"
          className="space-x-1 text-primary flex"
        >
          {currentPriceNumeric === 0 ? (
            <Badge className="px-2" color="success">Free</Badge>
          ) : (
            <>
              <span>
                {currency}
                {currentPrice}
              </span>
              {isDiscount && (
                <Badge color="failure">{discountPercentage.toFixed(0)}%</Badge>
              )}
              {isDiscount && (
                <span className="line-through decoration-2 text-gray-400 font-medium">
                  {currency}
                  {masterPrice}
                </span>
              )}
            </>
          )}
        </Card.Title>
        <Card.Title className="truncate">{title}</Card.Title>
      </div>
      <div className={clsx("flex justify-between items-center gap-2")}>
        {speakersArray.map((speaker: string, index: number) => (
          <div key={index}>
            <Typography variant="text4" className="font-bold">
              Mentor
            </Typography>
            <Typography
              as="h4"
              variant="text4"
              className="font-semibold text-tertiary"
            >
              {speaker}
            </Typography>
          </div>
        ))}
      </div>
      <div className="flex">
        <div className="p-2 px-3 bg-primary/10 rounded-md">
          <Typography
            variant="text4"
            className="font-semibold text-primary "
          >
            {workshopStatus()}
          </Typography>
        </div>
      </div>
    </Card>
  )
}

export default WorkshopCard
