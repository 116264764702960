import React from 'react';

interface Source {
  maxHeader: string,       // Media query condition, e.g., "(max-width: 768px)"
  responsiveSrc: string,  // Image source for that condition
}

interface ResponsiveImageProps {
  sources: Source[],      // Array of {maxHeader, responsiveSrc} pairs
  src: string,            // Default image source for larger screens
}

const ResponsiveImage: React.FC<ResponsiveImageProps> = ({ sources, src }) => {
  return (
    <picture>
      {sources.map((source, index) => {
        return <source
          key={index}
          media={source.maxHeader}
          srcSet={source.responsiveSrc}
        />
      })}

      <img src={src} alt="Responsive" />
    </picture>
  );
};

export default ResponsiveImage;