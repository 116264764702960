import RedemptionCodeForm from "components/cms/Course/redemption-code/RedemptionCodeForm"
import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "components/ui/breadcrumb/DefBreadcrumb"
import Typography from "components/ui/typography"
import { getCMSSegments } from "utils/helper"

const CourseRedemptionCodeCreate = () => {
  const segments = getCMSSegments()
  return (
    <EmployeeDashboardWrapper>
      <DefBreadcrumb
        segments={segments}
        prefix="cms"
      />
      <div className="inline-flex items-center">
        <Typography
          variant="subheading4"
          as="h1"
          className="text-primary mt-4"
        >
          Form Tambah Redeem Code
        </Typography>
      </div>

      <RedemptionCodeForm
        segments={segments}
      />

    </EmployeeDashboardWrapper>
  )
}

export default CourseRedemptionCodeCreate
