import { useState, useEffect, useMemo, useRef } from "react"
import { useDebounce, useMediaQuery } from "usehooks-ts"
import { useForm } from "react-hook-form"

import SlideDropdownFilter from "components/ui/filter/SlideDropdownFilter"
import SlideFilter from "components/ui/filter/SideFilter"

import CategoryCareers from "./CategoryCareers"
import { useGetCareerCategories } from "utils/api/public/queries/hooks"
import CardHorizontalSkeleton from "components/public/components/CardHorizontalSkeleton"
import Modal from "components/ui/modal/ModalFilter"
import Button from "components/ui/button/Button"

import { Category } from "utils/types"
import ButtonWithIcon from "components/ui/button/ButtonWithIcon"

import { ReactComponent as FilterIcon } from "assets/svg/Bi_Filter.svg"

const ListCareer = () => {
  const desktop = useMediaQuery("(min-width: 1024px)")
  const mobile = !desktop

  const { register, watch } = useForm()
  const searchValue = useDebounce<string>(watch("search"), 500)
  const [modalDelete, setModalDelete] = useState({
    open: false,
  })
  const { data: dataCategories, isLoading: isLoadingCategories } =
    useGetCareerCategories({
      options: {
        staleTime: 5 * 60 * 1000,
      },
    })

  const handleModalDelete = () => {
    console.log(modalDelete.open)
    setModalDelete((prev) => ({
      open: !prev.open,
    }))
  }

  const categoryItems = useMemo(() => {
    if (dataCategories?.data) {
      return dataCategories.data.map((category) => ({
        name: category.name,
        value: category.id,
      }))
    }
    return []
  }, [dataCategories])

  const selectedCategoryId = parseInt(watch("category")) || 0
  const [filteredCategoryItems, setFilteredCategoryItems] = useState<
    Category[]
  >([])

  useEffect(() => {
    const filterAndUpdateCategoryItems = () => {
      const updatedFilteredItems = categoryItems?.filter((category) => {
        if (selectedCategoryId === 0) {
          return true
        }
        return category.value === selectedCategoryId
      })

      setFilteredCategoryItems(updatedFilteredItems || [])
    }

    filterAndUpdateCategoryItems()
  }, [isLoadingCategories])

  useEffect(() => {
    const updatedFilteredItems = categoryItems?.filter((category) => {
      if (selectedCategoryId === 0) {
        return true
      }

      return category.value === selectedCategoryId
    })

    setFilteredCategoryItems(updatedFilteredItems || [])
  }, [selectedCategoryId])

  return (
    <div className="flex flex-col gap-4">
      {desktop && (
        <div className="sticky bg-white top-[4rem] z-20 h-fit">
          <div className="w-full flex gap-3 p-5 px-2 justify-end">
            <SlideDropdownFilter
              sections={[
                {
                  category: "Career",
                  register: register("category"),
                  items: [
                    { name: "All Career", value: 0 },
                    ...(categoryItems ?? []),
                  ],
                },
              ]}
            />
            <SlideDropdownFilter
              sections={[
                {
                  category: "Location",
                  register: register("search"),
                  items: [
                    { name: "All Location", value: "" },
                    .../*locationDummy ??*/[],
                  ],
                },
              ]}
            />
          </div>
        </div>
      )}
      {mobile && (
        <div className="w-full  flex gap-3 p-5 justify-end items-end">
          <ButtonWithIcon
            type="button"
            action={() => handleModalDelete()}
            class="text-black border-gray-300 border rounded-[3px] active:brightness-100 hover:brightness-100"
          >
            <FilterIcon className="h-6 w-6 mr-2 text-black" />
            Filter
          </ButtonWithIcon>
          <Modal
            header="Filter Karir"
            size="md"
            onclose={handleModalDelete}
            show={modalDelete.open}
            position="center-right"
            body={
              <div className="">
                <SlideFilter
                  sections={[
                    {
                      category: "Career",
                      register: register("category"),
                      items: [
                        { name: "All Career", value: 0 },
                        ...(categoryItems ?? []),
                      ],
                    },
                  ]}
                />
                <SlideFilter
                  sections={[
                    {
                      category: "Location",
                      register: register("search"),
                      items: [
                        { name: "All Location", value: "" },
                        .../*locationDummy ??*/[],
                      ],
                    },
                  ]}
                />
              </div>
            }
            footer={
              <div className="flex items-center justify-center w-full ml-auto">
                <Button
                  onClick={() => handleModalDelete()}
                  variant="contained"
                  fullWidth={true}
                  className=" border-gray-300"
                >
                  Apply Filter
                </Button>
              </div>
            }
          />
        </div>
      )}
      <div
        className={`lg:flex ${filteredCategoryItems && filteredCategoryItems.length > 6
          ? "lg:flex-wrap"
          : ""
          } grid gap-4`}
      >
        {isLoadingCategories ? (
          <div className="grid grid-cols-2">
            {[...Array(8)].map((_, index) => (
              <CardHorizontalSkeleton key={index} />
            ))}
          </div>
        ) : (
          <CategoryCareers
            categoryIds={categoryItems.map(category => category.value)}
            filteredCategoryIds={filteredCategoryItems.map(category => category.value)}
            searchValue={searchValue}
          />
        )}
      </div>
    </div>
  )
}

export default ListCareer
