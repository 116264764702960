import React, { useEffect, useMemo, useState } from "react"
import { toast } from "react-toastify"
import { LoaderFunction, useLoaderData, useNavigate } from "react-router-dom"
import {
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DocumentPlusIcon,
  EyeIcon,
  NoSymbolIcon,
  PencilSquareIcon,
  UserIcon,
} from "@heroicons/react/24/solid"
import to from "await-to-js"
import { createColumnHelper } from "@tanstack/react-table"
import ReactSimpleImageViewer from "react-simple-image-viewer"

import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "components/ui/breadcrumb/DefBreadcrumb"
import { CMSWebinarListResponse, Webinar } from "utils/types"
import api from "utils/axiosInstance"
import ButtonWithIcon from "components/ui/button/ButtonWithIcon"
import { getCMSSegments } from "utils/helper"
import useFlashMessage from "hooks/useFlashMessage"
import usePagination from "hooks/usePagination"
import DarkPagination from "components/ui/pagination/DarkPagination"
import ReactTable from "components/ui/react-table/ReactTable"
import CMSDefaultTableListFilter from "components/cms/CMSDefaultTableListFilter"
import FileInput from "components/ui/form/FileInput"
import { useMutationUploadThumbnailWebinar } from "utils/api/cms/mutations/hooks"
import { Tooltip } from "flowbite-react"
import useMutationUpdateWebinar from "utils/api/cms/mutations/hooks/useMutationUpdateWebinar"
import { UpdateWebinarRequestPayload } from "utils/api/cms/types"
import moment from "moment"
import Modal from "components/ui/modal/Modal"
import Typography from "components/ui/typography"
import Button from "components/ui/button/Button"
import clsx from "clsx"

const WebinarManagement: React.FC = () => {
  const segments = getCMSSegments()
  const loaderResponse = useLoaderData() as CMSWebinarListResponse<Webinar>
  const navigate = useNavigate()
  const [keyword, setKeyword] = useState("")
  const [perPage, setPerpage] = useState(10)
  const [webinarsResponse, setWebinarsResponse] =
    useState<CMSWebinarListResponse<Webinar>>()
  const [previewThumbnailID, setPreviewThumbnailID] = useState<number>()
  const [isPreviewOpen, setIsPreviewOpen] = useState(false)
  const [modalActivationWebinar, setModalActivationWebinar] = useState<{
    isOpen: boolean
    id: number
    payload: UpdateWebinarRequestPayload | null
    type: 'activation' | 'deactivation' | ''
  }>()

  useFlashMessage(
    "success",
    "webinarUpsertSuccess",
    "Berhasil menyimpan modul webinar"
  )
  const webinarFetcher = async () => {
    const page = Math.min(currentPage + 1, totalPages)
    const [_, resultResponse] = await to<CMSWebinarListResponse<Webinar>>(
      api.cms.getWebinars({
        page: page,
        keyword: keyword,
        limit: perPage,
      })
    )

    setTotalPages(resultResponse!.meta!.last_page)
    setWebinarsResponse(resultResponse)
  }
  const {
    totalPages,
    currentPage,
    initialPage,
    setTotalPages,
    setCurrentPage,
    setInitialPage,
    setShouldFetch,
    setForced,
  } = usePagination(webinarFetcher, [keyword, perPage])

  useFlashMessage(
    "success",
    "webinarUpsertSuccess",
    "Berhasil menyimpan modul Webinar"
  )

  const { mutate: mutateUploadFile } = useMutationUploadThumbnailWebinar({
    onSuccess(data, variables) {
      if (!data.success) return

      toast.success("Berhasil upload thumbnail")
      setWebinarsResponse((prev) => {
        if (!prev) return
        return {
          ...prev,
          data: prev.data.map((webinar) => {
            if (webinar.id.toString() === variables.webinarID) {
              return {
                ...webinar,
                thumbnail: data.data.thumbnail,
              }
            }
            return webinar
          }),
        }
      })
    },
  })

  const { mutate: mutateUpdateWebinar } = useMutationUpdateWebinar({
    onSuccess(data, variables) {
      if (!data.success) return

      toast.success(`Berhasil ${modalActivationWebinar?.type} webinar`)
      handleModalActivationWebinar('', undefined, null)

      setWebinarsResponse((prev) => {
        if (!prev) return
        return {
          ...prev,
          data: prev.data.map((webinar) => {
            if (webinar.id === data.data.id) {
              return {
                ...webinar,
                is_active: data.data.is_active,
              }
            }

            return webinar
          }),
        }
      })
    }
  })

  useEffect(() => {
    if (loaderResponse) {
      setWebinarsResponse(loaderResponse)

      setTotalPages(loaderResponse.meta!.last_page)

      if (loaderResponse.meta!.current_page > 1) {
        setInitialPage(loaderResponse.meta!.current_page - 1)
      }
      setShouldFetch(true)
    }
  }, [loaderResponse])

  const webinarNameSearchHandler = (keywordVal) => {
    setForced(true)
    setCurrentPage(0)
    setKeyword(keywordVal)
  }
  const perPageHandler = (selected: number) => {
    setForced(true)
    setCurrentPage(0)
    setPerpage(selected)
  }

  const handleChangeFile =
    (webinarID: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0]
      if (!file) return
      mutateUploadFile({
        webinarID: webinarID,
        payload: { thumbnail: file },
      })
    }

  const handleDeleteFile = (webinarID: number) => {
    setWebinarsResponse((prev) => {
      if (!prev) return
      return {
        ...prev,
        data: prev.data.map((webinar) => {
          if (webinar.id === webinarID) {
            return {
              ...webinar,
              thumbnail: "",
            }
          }

          return webinar
        }),
      }
    })
  }

  const handlePreview = (index?: number) => {
    setPreviewThumbnailID(index)
    setIsPreviewOpen((prev) => !prev)
  }

  const handleModalActivationWebinar = (
    type: 'activation' | 'deactivation' | '' = '',
    id: number = 0,
    payload: UpdateWebinarRequestPayload | null
  ) => {
    setModalActivationWebinar({
      isOpen: !modalActivationWebinar?.isOpen,
      id: id,
      payload: payload ?? null,
      type,
    })
  }

  const colHelper = createColumnHelper<Webinar>()
  const webinarColumns = [
    colHelper.accessor("title", {
      header: () => "Judul Modul Belajar",
    }),
    colHelper.accessor("thumbnail", {
      header: () => "Thumbnail",
      cell: (props) => (
        <div className="relative w-[120px] h-[85px]">
          <FileInput
            label="Upload Gambar"
            name="thumbnail"
            value={props.getValue()}
            onChange={handleChangeFile(props.row.original.id.toString())}
            onDelete={() => handleDeleteFile(props.row.original.id)}
            onPreview={() => handlePreview(props.row.index)}
            accept="image/png, image/jpg"
          />
        </div>
      ),
    }),
    colHelper.accessor("extras", {
      header: () => "Mentor",
      cell: (props) => {
        const extras = props.getValue();
        if (extras) {
          const { speakers } = JSON.parse(extras);
          return speakers;
        } else {
          return null;
        }
      }
    }),
    colHelper.accessor("price", {
      header: () => "Harga",
      cell: (props) => (
        <dl className="max-w-md text-white divide-y divide-gray-200">
          <div className="flex flex-col pb-3">
            <dt className="mb-1 text-gray-400 md:text-lg">Harga Asli</dt>
            <dd className="text-lg font-semibold">{`${props.getValue().currency_text
              } ${props.getValue().master_price_text}`}</dd>
          </div>
          <div className="flex flex-col pb-3">
            <dt className="mb-1 text-gray-400 md:text-lg">Harga Terkini</dt>
            <dd className="text-lg font-semibold">{`${props.getValue().currency_text
              } ${props.getValue().current_price_text}`}</dd>
          </div>
        </dl>
      ),
    }),
    colHelper.accessor("is_active", {
      header: () => "Status Modul",
      cell: (props) => {
        if (!props.getValue()) {
          return (
            <span className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded bg-yellow-900 text-yellow-300">
              PENDING
            </span>
          )
        }

        return (
          <span className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded bg-green-900 text-green-300">
            Aktif
          </span>
        )
      },
    }),
    colHelper.accessor("categories", {
      header: () => "Kategori",
      cell: (props) => {
        return (
          <ul className="max-w-md space-y-1 text-white list-none list-inside">
            {props
              .getValue()
              ?.map((category) => <li key={category.id}>{category.name}</li>)}
          </ul>
        )
      },
    }),
    colHelper.display({
      id: "actions",
      header: () => "AKSI",
      cell: (props) => {
        const rowData = props.row.original
        const updateWebinarRequest: UpdateWebinarRequestPayload = {
          is_active: rowData.is_active,
          title: rowData.title,
          description: rowData.description,
          price: rowData.price.current_price,
          quota: rowData.quota,
          start_date: moment(rowData.start_date).format("YYYY-MM-DD HH:mm"),
          end_date: moment(rowData.end_date).format("YYYY-MM-DD HH:mm"),
          extras: rowData.extras,
          category_ids: rowData.categories?.map(category => category.id) ?? [],
        }

        return (
          <div className="flex flex-row w-fit space-x-3">
            <Tooltip content="Edit">
              <ButtonWithIcon
                class="mx-auto border-2 border-transparent bg-yellow-400 text-white hover:bg-yellow-300 hover:border-yellow-300"
                type="button"
                action={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.preventDefault()
                  return navigate(`edit/${props.row.original.id}`)
                }}
                sizeClass="p-1"
              >
                <PencilSquareIcon className="h-5 w-5" />
              </ButtonWithIcon>
            </Tooltip>
            <Tooltip content="Detail">
              <ButtonWithIcon
                class="mx-auto border-2 border-transparent bg-blue-600 text-white hover:bg-blue-500 hover:border-blue-500"
                type="button"
                action={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.preventDefault()
                  return navigate(`detail/${props.row.original.id}`)
                }}
                sizeClass="p-1"
              >
                <EyeIcon className="h-5 w-5" />
              </ButtonWithIcon>
            </Tooltip>
            <Tooltip content="Peserta">
              <ButtonWithIcon
                class="mx-auto border-2 border-transparent bg-purple-600 text-white hover:bg-purple-500 hover:border-purple-500"
                type="button"
                action={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.preventDefault()
                  return navigate(`peserta/${props.row.original.id}`)
                }}
                sizeClass="p-1"
              >
                <UserIcon className="h-5 w-5" />
              </ButtonWithIcon>
            </Tooltip>
            {rowData.is_active &&
              <Tooltip content="Deactivate">
                <ButtonWithIcon
                  class="mx-auto border-2 border-transparent bg-red-600 text-white hover:bg-red-500 hover:border-red-500"
                  type="button"
                  action={() => {
                    handleModalActivationWebinar(
                      'deactivation',
                      rowData.id,
                      {
                        ...updateWebinarRequest,
                        is_active: false
                      }
                    )
                  }}
                  sizeClass="p-1"
                >
                  <NoSymbolIcon className="h-5 w-5" />
                </ButtonWithIcon>
              </Tooltip>
            }
            {!rowData.is_active &&
              <Tooltip content="Activate">
                <ButtonWithIcon
                  class="mx-auto border-2 border-transparent bg-green-600 text-white hover:bg-green-500 hover:border-green-500"
                  type="button"
                  action={() => {
                    handleModalActivationWebinar(
                      'activation',
                      rowData.id,
                      {
                        ...updateWebinarRequest,
                        is_active: true
                      }
                    )
                  }}
                  sizeClass="p-1"
                >
                  <CheckIcon className="h-5 w-5" />
                </ButtonWithIcon>
              </Tooltip>
            }
          </div>
        )
      },
    }),
  ]

  const previewImages = useMemo(
    () => webinarsResponse?.data.map((webinar) => webinar.thumbnail),
    [webinarsResponse]
  )

  return (
    <EmployeeDashboardWrapper>
      <DefBreadcrumb
        segments={segments}
        prefix="cms"
      />
      <h2 className="text-xl text-primary font-semibold leading-loose mt-4 tracking-wide">
        Pengaturan Konten Webinar
      </h2>

      <div className="flex flex-wrap justify-between gap-4 my-6">
        <ButtonWithIcon
          action={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault()
            return navigate("create")
          }}
          type="submit"
          class="border-2 border-transparent bg-primary text-white space-x-2 hover:bg-transparent hover:text-white hover:border-primary"
        >
          <DocumentPlusIcon className="h-6 w-6 text-white" />
          <span>Tambah Webinar</span>
        </ButtonWithIcon>
        <CMSDefaultTableListFilter
          pageSelector
          searchPlaceHolder="Cari nama konten webinar"
          wrapperClass="flex flex-row space-x-6"
          onSearchHandler={webinarNameSearchHandler}
          perPageHandler={perPageHandler}
        />
      </div>
      {webinarsResponse && (
        <>
          <ReactTable
            columns={webinarColumns}
            data={webinarsResponse!.data}
          />
          <div className="flex pb-8 items-center ml-auto">
            <DarkPagination
              nexLabel={<ChevronRightIcon className="h-5 w-5 text-gray-400" />}
              previousLabel={
                <ChevronLeftIcon className="h-5 w-5 text-gray-400" />
              }
              pageCount={totalPages}
              pageRangeDisplayed={2}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
              initialPage={initialPage}
            />
          </div>
          {isPreviewOpen && (
            <ReactSimpleImageViewer
              src={previewImages ?? []}
              currentIndex={previewThumbnailID}
              onClose={handlePreview}
              disableScroll
              backgroundStyle={{
                backgroundColor: "rgba(0,0,0,0.9)",
              }}
              closeOnClickOutside={true}
            />
          )}
        </>
      )}

      <Modal
        header={`${modalActivationWebinar?.type} webinar`}
        capitalizeHeader
        onclose={() => handleModalActivationWebinar('', 0, modalActivationWebinar?.payload ?? null)}
        show={!!modalActivationWebinar?.isOpen}
        body={
          <Typography className="text-white">
            Apakah Anda yakin untuk {modalActivationWebinar?.type} kelas{" "}
            <b>{modalActivationWebinar?.payload?.title}</b>?
          </Typography>
        }
        footer={
          <div className="space-x-2 ml-auto">
            <Button
              onClick={() => handleModalActivationWebinar('', 0, modalActivationWebinar?.payload ?? null)}
              variant="outlined"
              className="text-gray-300 border-gray-300"
            >
              Batal
            </Button>
            <Button
              onClick={() => {
                if (modalActivationWebinar?.payload == null) return

                mutateUpdateWebinar({
                  id: modalActivationWebinar?.id ?? 0,
                  payload: modalActivationWebinar?.payload ?? null
                })
              }}
              className={clsx('text-transform: capitalize')}
            >
              {modalActivationWebinar?.type}
            </Button>
          </div>
        }
      />
    </EmployeeDashboardWrapper>
  )
}

export default WebinarManagement

export const loadWebinars: LoaderFunction = async (): Promise<
  CMSWebinarListResponse<Webinar>
> => {
  const searchParams = new URLSearchParams(window.location.search)
  const response = await api.cms.getWebinars({
    keyword: searchParams.has("search")
      ? (searchParams.get("search") as string)
      : "",
    page: searchParams.has("page") ? +(searchParams.get("page") as string) : 1,
    limit: 10,
  })

  return response
}
