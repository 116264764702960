import React, { FC } from "react";
import ButtonWithIcon from "./ButtonWithIcon";
import { TableCellsIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import to from "await-to-js";
import { AxiosResponse } from "axios";
import { appendTimestamp } from "utils/helper";

const ExcelExportButton: FC<{
  excelExportRequest: () => Promise<AxiosResponse<Blob>>;
  children?: React.ReactNode;
  class?: string;
  disabled?: boolean;
  sizeClass?: string;
  isLoading?: boolean;
  Icon?: React.ReactNode;
  filename?: string;
  appendTimestampInFilename?: boolean
}> = ({
  excelExportRequest,
  filename = 'data',
  appendTimestampInFilename = true,
  ...props
}) => {
    const exportExcel = async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()

      const [_, response] = await to<AxiosResponse<Blob>>(excelExportRequest())

      if (!(response instanceof Blob)) {
        return;
      }

      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;

      const filenameDownload = appendTimestampInFilename ?
        appendTimestamp(filename) : filename;

      link.setAttribute('download', `${filenameDownload}.xlsx`);

      document.body.appendChild(link);
      link.click();
    }

    return (
      <ButtonWithIcon
        type={"button"}
        action={exportExcel}
        disabled={props.disabled}
        isLoading={props.isLoading}
        sizeClass={props.sizeClass}
        class={clsx(
          "ml-auto border-2 border-transparent",
          "bg-primary text-white space-x-2",
          "hover:bg-transparent hover:text-white hover:border-primary",
          props.class,
        )}
      >
        <TableCellsIcon className="h-5 w-5 text-white" />
        <span>Export Excel</span>
        {props.children}
      </ButtonWithIcon>
    )
  }

export default ExcelExportButton