import { useMutation } from "@tanstack/react-query"

import { GeneralMutationParams } from "utils/types"
import { CourseGiftActivationRequest, CourseGiftActivationResponse } from "utils/api/cms/types"
import courseGiftActivation from "../../functions/course/courseGiftActivation"

const useMutationCourseGiftActivation = (
  options?: GeneralMutationParams<
    CourseGiftActivationResponse,
    CourseGiftActivationRequest
  >
) => {
  return useMutation({
    mutationKey: ['test'],
    mutationFn: (payload) => courseGiftActivation(payload),
    ...options,
  })
}

export default useMutationCourseGiftActivation
